import { Application } from "typedoc";

export class Logger {
  startTime: number = Date.now();

  baseStyles: string = `
    color: #fff;
    background-color: #ca0031;
    padding: 2px 4px;
    border-radius: 3px;`;

  timestampStlyes: string = `
    color: #fff;
    background-color: #0000ca;
    padding: 2px 4px;
    border-radius: 3px;`;

  private debugLogging: boolean = false;

  constructor() {
    if (window.sessionStorage.getItem("mastodonDebugLogging") === "true") {
      this.debugLogging = true;
    }
  }

  setDebugLogging(debug: boolean) {
    this.debugLogging = debug;
  }

  timeElapsed() {
    return Date.now() - this.startTime;
  }

  log(...args) {
    if (this.debugLogging)
      console.log.apply(this, [
        "%cMastodon",
        this.baseStyles,
        this.timeElapsed(),
        ...args,
      ]);
  }

  warn(...args) {
    console.warn.apply(this, [
      "%cMastodon",
      this.baseStyles,
      this.timeElapsed(),
      ...args,
    ]);
  }

  error(...args) {
    console.error.apply(this, [
      "%cMastodon",
      this.baseStyles,
      this.timeElapsed(),
      ...args,
    ]);
  }

  group(label?: string) {
    if (this.debugLogging) console.group("%cMastodon", this.baseStyles, label);
  }

  endGroup() {
    console.groupEnd();
  }

  hero() {
    if (this.debugLogging)
      console.log(
        `%c
██████   ██████                    █████                 █████                    
░░██████ ██████                    ░░███                 ░░███                     
 ░███░█████░███   ██████    █████  ███████    ██████   ███████   ██████  ████████  
 ░███░░███ ░███  ░░░░░███  ███░░  ░░░███░    ███░░███ ███░░███  ███░░███░░███░░███ 
 ░███ ░░░  ░███   ███████ ░░█████   ░███    ░███ ░███░███ ░███ ░███ ░███ ░███ ░███ 
 ░███      ░███  ███░░███  ░░░░███  ░███ ███░███ ░███░███ ░███ ░███ ░███ ░███ ░███ 
 █████     █████░░████████ ██████   ░░█████ ░░██████ ░░████████░░██████  ████ █████
░░░░░     ░░░░░  ░░░░░░░░ ░░░░░░     ░░░░░   ░░░░░░   ░░░░░░░░  ░░░░░░  ░░░░ ░░░░░ 

░█▀▄░█░█░░░█▄█░█▀█░█▀█░█░░░█▀▀░░░█▄█░█▀▀░█▀▄░▀█▀░█▀█
░█▀▄░░█░░░░█░█░█▀█░█▀▀░█░░░█▀▀░░░█░█░█▀▀░█░█░░█░░█▀█
░▀▀░░░▀░░░░▀░▀░▀░▀░▀░░░▀▀▀░▀▀▀░░░▀░▀░▀▀▀░▀▀░░▀▀▀░▀░▀                            
Version ${process.env.MASTODON_VERSION}
`,
        "color:#ca0031;"
      );
  }
}

const masterLogger = new Logger();
export default masterLogger;

export class SubLogger {
  prefix: string = "";
  constructor(prefix: string) {
    this.prefix = prefix;
  }

  log(...args) {
    masterLogger.log.apply(masterLogger, [this.prefix, ...args]);
  }

  warn(...args) {
    masterLogger.warn.apply(masterLogger, [this.prefix, ...args]);
  }

  error(...args) {
    masterLogger.error.apply(masterLogger, [this.prefix, ...args]);
  }

  group(label?: string) {
    masterLogger.group(label);
  }

  endGroup() {
    masterLogger.endGroup();
  }
}
