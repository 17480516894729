import ElementRule from "..";

export default class RuleAction {
  rule: ElementRule;
  parameters: any;

  constructor(params: any) {
    this.parameters = params;
  }

  perform() {
    var elements = document.querySelectorAll<HTMLElement>(
      this.rule.elementSelector
    );
    elements.forEach((element) => {
      this.performOnElement(element);
    });
  }

  performOnElement(element: HTMLElement) {
    throw "Not implemented";
  }
}
