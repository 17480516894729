/**
 * @module Uid2Provider
 * docs: https://unifiedid.com/docs/guides/publisher-client-side
 */

import ConfigManager from "../../ConfigManager";
import Logger from "../../Logger";
import { hex2base64 } from "../../Utils";
import { injectScriptAsync } from "../../injectScript";
import pbjs from "../../pbjs";
import UserIdManager from "../UserIdManager";
import BaseUserIdProvider from "./BaseUserIdProvider";

const prodApiUrl = "https://cdn.prod.uidapi.com/uid2-sdk-3.2.0.js";
const devApiUrl = "https://cdn.integ.uidapi.com/uid2-sdk-3.2.0.js";
const clientSideConfig = {
  subscriptionId: "kGsvBA6Gt3",
  serverPublicKey:
    "UID2-X-P-MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEyCbJ9I+/vlerLPY/7VsA+yhp54EZkUVhyPqEFMcgtN4SuuIkp/8GJ+IzLqJddGW3wQxn66MimPv80fHyd1z2QA==",
};

class Uid2Provider extends BaseUserIdProvider {
  identity: any;

  constructor() {
    super();
  }

  async init() {
    /* commenting out as the prebid and javascript versions don't seem to be compatible.
     Using only prebid implementation instead. 
     
     */
    // Logger.log("Initializing Uid2Provider", ConfigManager.env);
    // // if (ConfigManager.env === "prod") {
    // await injectScriptAsync(prodApiUrl);
    // // } else {
    // //   await injectScriptAsync(devApiUrl);
    // // }
    // Logger.log("Initialized Uid2Provider");
  }

  async configure(): Promise<void> {
    /* commenting out as the prebid and javascript versions don't seem to be compatible.
     Using only prebid implementation instead. 
     
     */
    // Logger.log("Configuring Uid2Provider");
    // window.__uid2 = window.__uid2 || {};
    // window.__uid2.callbacks = window.__uid2.callbacks || [];
    // let resolved = false;
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     if (resolved) return;
    //     Logger.warn("Uid2Provider timeout");
    //     resolved = true;
    //     resolve();
    //   }, UserIdManager.PROVIDER_TIMEOUT);
    //   window.__uid2.callbacks.push(async (eventType, payload) => {
    //     switch (eventType) {
    //       case "SdkLoaded":
    //         // The SdkLoaded event occurs just once.
    //         window.__uid2.init({});
    //         break;
    //       case "InitCompleted":
    //         // The InitCompleted event occurs just once.
    //         //
    //         // If there is a valid UID2 token, it is in payload.identity.
    //         if (payload.identity) {
    //           //
    //           // payload looks like this:
    //           // {
    //           //   "identity": {
    //           //     "advertising_token": "A4A...MqA",
    //           //     "refresh_token": "A3A...pdg==",
    //           //     "identity_expires": 1692257038260,
    //           //     "refresh_expires": 1692339838260,
    //           //     "refresh_from": 1692254338260
    //           //     "refresh_response_key": "z0v...zL0="
    //           //   }
    //           // }
    //           Logger.log("Uid2Provider token is available", payload.identity);
    //           this.identity = payload.identity;
    //           resolved = true;
    //           resolve();
    //         } else {
    //           if (window.__uid2.isLoginRequired()) {
    //             // Call one of the setIdentityFrom functions to generate a new UID2 token.
    //             // Add any retry logic around this call as required.
    //             try {
    //               const hash = hex2base64(UserIdManager.emailSHA256);
    //               Logger.log(
    //                 "Uid2Provider is not available, setting identity",
    //                 hash,
    //                 clientSideConfig
    //               );
    //               await window.__uid2.setIdentityFromEmailHash(
    //                 hash,
    //                 clientSideConfig
    //               );
    //             } catch (e) {
    //               Logger.error("Uid2Provider Error setting identity", e);
    //               resolved = true;
    //               resolve();
    //             }
    //           } else {
    //             // there is a token generation API call in flight which triggers
    //             // a IdentityUpdated event
    //           }
    //         }
    //         break;
    //       case "IdentityUpdated":
    //         Logger.log("Uid2Provider is available", payload.identity);
    //         // The IdentityUpdated event happens when a UID2 token is generated or refreshed.
    //         // payload.identity contains the resulting latest identity.
    //         this.identity = payload.identity;
    //         resolved = true;
    //         resolve();
    //         break;
    //     }
    //   });
    // });
  }
  async getUserId() {
    // get user id from prebid module
    return pbjs()?.getUserIds()?.uid2;
    // Get the user ID
    // return this.identity?.advertising_token;
  }

  getPrebidConfiguration(): Object {
    if (!UserIdManager.emailSHA256) {
      Logger.error("Uid2Provider: emailSHA256 is not available");
      return { name: "uid2", params: {} };
    }
    const hash = hex2base64(UserIdManager.emailSHA256 ?? "");
    return {
      name: "uid2",
      params: {
        serverPublicKey: clientSideConfig.serverPublicKey,
        subscriptionId: clientSideConfig.subscriptionId,
        // Choose only one of the following: email, emailHash, phone, or phoneHash
        //email: "user@example.com", // Normalized or non-normalized, unhashed email address
        emailHash: hash, // Normalized and hashed email address
        // phone: '+1111111111', // Normalized phone number
        // phoneHash: 'eVvLS/Vg+YZ6+z3i0NOpSXYyQAfEXqCZ7BTpAjFUBUc=', // Normalized and hashed phone number

        // Use dev environment for testing
        // uid2ApiBase:
        //   ConfigManager.env === "prod"
        //     ? undefined
        //     : "https://operator-integ.uidapi.com",
      },
    };
  }
}

export default new Uid2Provider();
