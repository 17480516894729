import AdSystem from "../";
import ConfigManager from "../../ConfigManager";
import Logger from "../../Logger";
import pbjs from "../../pbjs";
import googletag from "../../googletag";
import PrebidManager from "../../PrebidManager";
import GoogletagManager from "../../GoogletagManager";
import Auction from "./GamAuction";
import ScreenDetector from "../../ScreenDetector";
import {
  AuctionConfig,
  SlotUnion,
  AuctionAdapterName,
  DestroySlotsOptions,
} from "../../Types";
import DomAdManager from "../../DomAdManager";
import AdRefreshManager from "../../AdRefreshManager";
import ApstagManager from "../../ApstagManager";
import ContainerSizeManager, {
  ContainerSize,
} from "../../ContainerSizeManager";
import UserIdManager from "../../UserId/UserIdManager";
import BlacklistManager from "../../BlacklistManager";

class GamAdSystem extends AdSystem {
  private defaultAuctionAdapters: AuctionAdapterName[] = ["pbjs", "amazon"];
  private defaultAuctionConfig: AuctionConfig;

  constructor() {
    super();
  }

  async init(): Promise<void> {
    Logger.log("Initializing GamAdSystem");
    GoogletagManager.init();
    PrebidManager.configure();
    ApstagManager.configure();
    pbjs().que.push(() => Logger.log("PBJS loaded", pbjs()));
    googletag().cmd.push(() => Logger.log("Googletag loaded", googletag()));
    await UserIdManager.init();
  }

  /**
   * Sets the default configuration for all auctions
   * @param config
   */
  setDefaultAuctionConfig(config: AuctionConfig) {
    Logger.log("Setting default auction config", config);
    this.defaultAuctionConfig = config;
  }

  /**
   * Sets the adapters to use for all auctions
   * @param adapterNames array of adapter names. Currently support 'pbjs' and 'amazon'
   */
  useAdapters(adapterNames: AuctionAdapterName[]) {
    Logger.log("Using adapters", adapterNames);
    this.defaultAuctionAdapters = adapterNames;
  }

  /**
   * Destroys ad slots.
   * If the slot was tracked automatically by {@link Mastodon.update}() then it will also remove the slot div
   * from the DOM.
   *
   * If an array of slots is not provided, it will destroy all slots.
   * @param slots The slots to destroy
   */
  destroySlots(
    slots?: SlotUnion[],
    options: DestroySlotsOptions = { clearRefresh: true, removeFromDom: true }
  ): void {
    const { clearRefresh, removeFromDom } = options;
    const s = slots ? this.getSlotObjects(slots) : null;
    const containers = slots ? this.getSlotContainerIDs(slots) : null;
    Logger.log("Destroying slots", slots, s);
    googletag().destroySlots(s ?? []);

    DomAdManager.removeSlots(slots, removeFromDom);
    ContainerSizeManager.removeContainers(containers ?? []);
    if (Array.isArray(slots)) {
      if (clearRefresh) {
        slots.forEach((slot) => AdRefreshManager.removeSlot(slot));
      }
      containers?.forEach((c) => {
        delete GoogletagManager.containerSlotMap[c];
      });
    } else {
      AdRefreshManager.removeAll();
    }
  }

  getSlotContainerID = (slot: SlotUnion): string => {
    if (typeof slot == "object") {
      return slot.getSlotElementId();
    } else {
      return slot;
    }
  };

  getSlotObject(slot: SlotUnion): googletag.Slot {
    if (typeof slot == "string")
      return GoogletagManager.containerSlotMap[slot].slot;
    else return slot;
  }

  getSlotObjects(slots: SlotUnion[]): googletag.Slot[] {
    return slots.map((slot) => this.getSlotObject(slot));
  }

  /**
   * Updates config to restrict size of ad. Destroys and rebuilds config and ad slot.
   * Does not reset if the new size doesn't change the sizes of ads at all.
   * Does not call loadAds() so that should be done manually.
   *
   * @param slot
   * @param width
   * @param height
   * @return Returns true if reset wa necesarry, false if no reset required.
   */
  setSlotSizeAndReset(slot: SlotUnion, width: number, height: number): boolean {
    const containerId = this.getSlotContainerID(slot);

    const adUnit = this.getAdUnitForContainerId(containerId);

    const previousSizes = JSON.stringify(
      ConfigManager.getContainerSize(containerId)
    );
    this.setSlotSize(slot, width, height);
    const newSizes = JSON.stringify(
      ConfigManager.getContainerSize(containerId)
    );
    if (previousSizes != newSizes) {
      Logger.log("New ad sizes after slot changed size. Resetting ");
      PrebidManager.removeSlot(containerId);
      this.destroySlots([slot], { clearRefresh: false });
      this.setSlotSize(slot, width, height);
      if (adUnit) {
        GoogletagManager.addSlot(adUnit, containerId);
        this.display(containerId);
      }
      return true;
    } else {
      Logger.log("Container resize doesn't change ad sizes. Doing nothing");
      return false;
    }
  }
  /**
   * Returns container ids for each ad slot for the specified adUnit
   * @param adUnit
   * @return array of container ids active for that adUnit
   */
  getContainerIdsForAdUnit(adUnit: string): string[] {
    return Object.keys(GoogletagManager.containerSlotMap)
      .map((key) => {
        const obj = GoogletagManager.containerSlotMap[key];
        const au = obj.adUnitPath.split("/").pop();

        if (au == adUnit) return key;
        return null;
      })
      .filter((i) => i != null);
  }

  /**
   * Clears all slots content for the given slot container ids
   * @param slotContainerIds Container IDs of the slots
   *
   */
  clearSlotsById(slotContainerIds: string[]): boolean {
    const slots = this.getSlotObjects(slotContainerIds);
    Logger.log("Clearing slots", slotContainerIds, slots);
    return googletag()
      .pubads()
      .clear(slots || undefined);
  }

  /**
   * Clears all content for the given slots
   * @param slots The slot objects to clear
   * @returns
   */
  clearSlots(slots?: SlotUnion[]): boolean {
    if (!slots) return googletag().pubads().clear();
    const s = this.getSlotObjects(slots);
    return googletag().pubads().clear(s);
  }

  /**
   * Instructs the service to render the slot
   * @param slotId
   */
  display(slot: SlotUnion) {
    GoogletagManager.display(slot);
  }

  /**
   * Initiates an auction to display(or refresh) the specified ad slots
   * @param slots The slot objects or container ids of the slots to load
   * @param config Optional config
   */
  loadAds(slots?: SlotUnion[], config?: AuctionConfig) {
    let c = config
      ? {
          adapters: this.defaultAuctionAdapters,
          ...this.defaultAuctionConfig,
          ...config,
        }
      : {
          adapters: this.defaultAuctionAdapters,
          ...this.defaultAuctionConfig,
        };

    const containers = slots
      ? this.getSlotContainerIDs(slots)?.filter(
          (container) => !ConfigManager.isContainerDisabled(container)
        )
      : undefined;
    const auction = new Auction(containers, c);
    auction.run();
  }

  /**
   * Initiates an auction to display(or refresh) the specified ad slots
   * @param slots The slot objects or container ids of the slots to load
   * @param config Optional config
   */
  refreshAds(slots?: SlotUnion[], config?: AuctionConfig) {
    this.loadAds(slots, config);
  }

  /**
   * Adds a new ad slot to the page
   *
   * @param adUnit The ad unit to use for the slot
   * @param containerId The id of the div to use as a container for the slot
   * @returns
   */
  async addSlot(adUnit: string, containerId: string): Promise<void> {
    const slot = await GoogletagManager.addSlot(adUnit, containerId);
    const config = ConfigManager.getAdUnitConfig(adUnit);

    if (config?.refreshSeconds)
      AdRefreshManager.addSlot(containerId, config?.refreshSeconds);
  }

  do(fn: () => void) {
    if (BlacklistManager.isUrlBlacklisted()) {
      fn();
      return;
    }
    PrebidManager.do(() => {
      GoogletagManager.do(() => {
        fn();
      });
    });
  }

  /**
   * Enables debug logging
   */
  enableDebug() {
    pbjs().que.push(() => pbjs().setConfig({ debug: true }));
  }

  async update(): Promise<void> {
    await UserIdManager.init();
  }
}

export default GamAdSystem;
