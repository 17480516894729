import Logger from "../../Logger";
import UserIdManager from "../UserIdManager";
import BaseUserIdProvider from "./BaseUserIdProvider";
import ID5 from "@id5io/id5-api.js/lib/id5-api";
window.ID5 = ID5;
class Id5Provider extends BaseUserIdProvider {
  partnerId: number = 1469;
  id5: any;

  constructor() {
    super();
  }

  async init() {
    // nothing to do for id5, since the JS is bundled in mastodon
    Logger.log("Initializing Id5Provider", ID5);
  }

  configure(): Promise<void> {
    // Configure the provider
    const pdKeys = {
      1: UserIdManager.emailSHA256,
      8: encodeURIComponent(UserIdManager.fullUrl),
      12: encodeURIComponent(UserIdManager.userAgent),
    };
    Logger.log("pdKeys", pdKeys);

    // convert the key/values into a querystring format
    const pdRaw = Object.keys(pdKeys)
      .map((key) => key + "=" + pdKeys[key])
      .join("&");

    // base64 encode the raw string; this is the final value you can pass into the pd field
    const pdString = Buffer.from(pdRaw).toString("base64");

    Logger.log("COnfiguring ID5 with", {
      partnerId: this.partnerId,
      pd: pdString,
      allowLocalStorageWithoutConsentApi: true,
      debugBypassConsent: true,
    });

    ID5.debug = true;
    this.id5 = ID5.init({ partnerId: this.partnerId, pd: pdString });
    Logger.log("Configuring Id5Provider", this.id5);
    this.id5.onUpdate(() => {
      this.onUpdate();
    });
    return new Promise((resolve, reject) => {
      this.id5.onAvailable(() => {
        this.onUpdate();
        resolve();
      }, UserIdManager.PROVIDER_TIMEOUT);
    });
  }

  onUpdate() {
    const userId = this.id5.getUserId();
    Logger.log("Id5Provider onUpdate", userId, this.id5.getUserIdAsEid());
    if (!userId) {
      Logger.log(
        "Id5Provider is available, but there is no userId",
        this.id5.getUserId()
      );
    } else {
      Logger.log("Id5Provider is available. UserID:", userId);
    }
  }

  async getUserId() {
    return this.id5.getUserId();
  }

  getPrebidConfiguration(): Object {
    return {
      name: "id5Id",
      params: {
        partner: this.partnerId, // same value as in the API config
      },
      storage: {
        type: "html5",
        name: "id5id",
        expires: 90,
        refreshInSeconds: 2 * 3600,
      },
    };
  }
}

export default new Id5Provider();
