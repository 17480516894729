import { Mastodon } from "./Mastodon";

export const cdnPath = (path) => `//${process.env.ASSET_CDN}${path}`;

/**
 * Converts a hex string to base64
 */
export function hex2base64(hex: string) {
  // convert hex to bytes
  let bytes = new Uint8Array(
    hex.match(/[\da-f]{2}/gi).map((h) => parseInt(h, 16))
  );
  // convert bytes to base64 web-safe
  return Buffer.from(bytes).toString("base64");
}

export function mastodon(): Mastodon {
  if (window.Mastodon && window.Mastodon instanceof Mastodon) {
    return window.Mastodon;
  }
}
