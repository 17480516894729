import Logger from "../../Logger";
import { injectScriptAsync } from "../../injectScript";
import UserIdManager from "../UserIdManager";
import BaseUserIdProvider from "./BaseUserIdProvider";

class LiveRampProvider extends BaseUserIdProvider {
  PLACEMENT_ID = "14066";

  constructor() {
    super();
  }

  async init() {
    // nothing to do for id5, since the JS is bundled in mastodon
    Logger.log("Initializing LiveRamp");
    //<script async defer src="https://launchpad-wrapper.privacymanager.io/b454ffc9-3086-438e-b52e-a3f4642db4e8/launchpad-liveramp.js"></script>
    await injectScriptAsync(
      "https://ats-wrapper.privacymanager.io/ats-modules/eb6db959-8008-4c70-9258-80df3c13a549/ats.js"
    );
  }

  async configure(): Promise<void> {
    Logger.log("Initializing LiveRamp");

    var emailHashes = [];

    if (UserIdManager.emailSHA1) {
      emailHashes.push(UserIdManager.emailSHA1);
    }
    if (UserIdManager.emailMD5) {
      emailHashes.push(UserIdManager.emailMD5);
    }
    if (UserIdManager.emailSHA256) {
      emailHashes.push(UserIdManager.emailSHA256);
    }
    if (emailHashes.length === 0) {
      Logger.log("No email hashes to send to LiveRamp");
      return;
    }
    Logger.log("Sending email hashes to LiveRamp", emailHashes);
    window.ats.setAdditionalData({
      type: "emailHashes",
      id: emailHashes,
    });
  }

  getPrebidConfiguration(): Object {
    return {
      name: "identityLink",
      params: {
        pid: this.PLACEMENT_ID, // Set your ATS Placement ID here
        notUse3P: true, // If you want to generate and use a RampID based on a LiveRamp 3p cookie (from a previous authentication) until ATS can generate a new RampID, set this property to false.
      },
      storage: {
        type: "cookie",
        name: "idl_env", // "idl_env" is the required storage name
        expires: 15, // Identity envelope can last for 15 days
        refreshInSeconds: 1800, // Identity envelope will be updated every 30 minutes
      },
    };
  }
}

export default new LiveRampProvider();
