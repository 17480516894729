import ElementRule from "..";

export default class RuleType {
  rule: ElementRule;
  parameters: any;

  constructor(params: any) {
    this.parameters = params;
  }

  async isApplicable(): Promise<boolean> {
    throw "Not implemented";
    return false;
  }
}
