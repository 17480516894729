import AdSystem from "../";
import AdRefreshManager from "../../AdRefreshManager";
import BlacklistManager from "../../BlacklistManager";
import ConfigManager from "../../ConfigManager";
import ContainerSizeManager, {
  ContainerSize,
} from "../../ContainerSizeManager";
import DomAdManager from "../../DomAdManager";
import injectScript, { injectLinkTag } from "../../injectScript";
import Logger, { SubLogger } from "../../Logger";
import { Mastodon } from "../../Mastodon";
import TargetingManager from "../../TargetingManager";
import {
  SlotUnion,
  DestroySlotsOptions,
  AuctionConfig,
  AuctionAdapterName,
} from "../../Types";
import { mastodon } from "../../Utils";

const logger = new SubLogger("FreestarAdSystem");
class FreestarAdSystem extends AdSystem {
  containerAdUnitMap = new Map<string, string>();
  freestarId: string;
  freestarStyleElement: HTMLStyleElement;
  placementSizeRegex = /([0-9]+)x([0-9]+)$/;

  constructor() {
    super();
  }

  async init(): Promise<void> {
    this.freestarId = ConfigManager.getFreestarId();
    // Initialization logic for FreestarAdSystem
    injectLinkTag("https://a.pub.network/", "preconnect", { crossorigin: "" });
    injectLinkTag("https://b.pub.network/", "preconnect", { crossorigin: "" });
    injectLinkTag("https://c.pub.network/", "preconnect", { crossorigin: "" });
    injectLinkTag("https://d.pub.network/", "preconnect", { crossorigin: "" });
    injectLinkTag("https://c.amazon-adsystem.com", "preconnect", {
      crossorigin: "",
    });
    injectLinkTag("https://s.amazon-adsystem.com", "preconnect", {
      crossorigin: "",
    });
    injectLinkTag("https://btloader.com/", "preconnect", { crossorigin: "" });
    injectLinkTag("https://api.btloader.com/", "preconnect", {
      crossorigin: "",
    });
    injectLinkTag("https://cdn.confiant-integrations.net", "preconnect", {
      crossorigin: "",
    });
    injectLinkTag(
      `https://a.pub.network/${this.freestarId}/cls.css`,
      "stylesheet"
    );

    window.freestar = window.freestar || {};
    let freestar = window.freestar;
    freestar.queue = freestar.queue || [];
    freestar.config = freestar.config || {};
    freestar.config.enabled_slots = [];
    freestar.initCallback = () => {
      freestar.config.enabled_slots.length === 0
        ? (freestar.initCallbackCalled = false)
        : freestar.newAdSlots(freestar.config.enabled_slots);
    };

    injectScript(`https://a.pub.network/${this.freestarId}/pubfig.min.js`, {
      "data-cfasync": "false",
      async: true,
    });
    logger.log("FreestarAdSystem initialized");
    this._checkForOneTrustBanner();
  }

  destroySlots(slots?: SlotUnion[], options?: DestroySlotsOptions): void {
    // Logic to destroy ad slots
    const { clearRefresh, removeFromDom } = options;
    const containers = slots ? this.getSlotContainerIDs(slots) : null;
    logger.log("Destroying slots", slots);

    this.do(() => {
      if (Array.isArray(slots)) {
        window.freestar.deleteAdSlots(containers);
        logger.log("Slots deleted", slots);
      } else {
        window.freestar.deleteAdSlots([...this.containerAdUnitMap.keys()]);
        logger.log("All slots deleted", this.containerAdUnitMap.keys());
      }
    });

    DomAdManager.removeSlots(slots, removeFromDom);

    ContainerSizeManager.removeContainers(containers ?? []);
    if (Array.isArray(slots)) {
      if (clearRefresh) {
        slots.forEach((slot) => AdRefreshManager.removeSlot(slot));
      }
      containers?.forEach((c) => {
        delete this.containerAdUnitMap[c];
      });
    } else {
      if (clearRefresh) {
        AdRefreshManager.removeAll();
      }
      this.containerAdUnitMap.clear();
    }
  }

  _injectCSS(css: string): HTMLStyleElement {
    let style = document.createElement("style");
    style.type = "text/css";
    style.innerText = css;
    document.head.appendChild(style);
    return style;
  }

  _checkForOneTrustBanner() {
    const checkLater = () => {
      setTimeout(this._checkForOneTrustBanner.bind(this), 200);
    };

    logger.log("OT Banner: Checking for OneTrust banner");
    if (!window.OneTrust?.IsAlertBoxClosed) {
      logger.log("OT Banner: OneTrust not found");
      checkLater();
      return;
    }
    const alertBoxClosed = window.OneTrust?.IsAlertBoxClosed();
    if (alertBoxClosed === null) {
      // check again
      logger.log("OT Banner: Alert box closed is null");

      checkLater();
      return;
    }

    if (alertBoxClosed) {
      logger.log("OT Banner: Alert box closed");
      // do nothing
      return;
    }

    const oneTrustBanner = document.getElementById("onetrust-banner-sdk");
    if (!oneTrustBanner) {
      logger.log("OT Banner: OneTrust banner not found");
      checkLater();
      return;
    }
    const bannerHeight = oneTrustBanner.clientHeight;
    this.freestarStyleElement = this._injectCSS(`
      #fs-sticky-footer{
        bottom: ${bannerHeight}px !important;
      }
      #fs-slot-footer-wrapper {
        bottom: ${bannerHeight}px !important;
      }
      `);

    mastodon().addEventListener(Mastodon.CMP_CONSENT_CHANGED, () => {
      logger.log("OT Banner: CMP consent changed");
      const alertBoxClosed = window.OneTrust?.IsAlertBoxClosed();
      if (alertBoxClosed) {
        logger.log("OT Banner: Alert box closed");
        this.freestarStyleElement?.remove();
      } else {
        logger.log("OT Banner: Alert box not closed");
      }
    });
  }

  getSlotContainerID(slot: SlotUnion): string {
    // Logic to get the container ID for a given slot
    if (typeof slot === "string") {
      return slot;
    }
    throw new Error("Invalid slot");
  }

  getSlotObject(slot: SlotUnion): googletag.Slot {
    // Logic to get the slot object for a given slot
    return {} as googletag.Slot;
  }

  getSlotObjects(slots: SlotUnion[]): googletag.Slot[] {
    // Logic to get an array of slot objects for the given slots
    return [] as googletag.Slot[];
  }

  setSlotSizeAndReset(slot: SlotUnion, width: number, height: number): boolean {
    // Logic to set the size of a slot and reset it if necessary
    logger.log(`Setting slot size to ${width}x${height}`);
    return true;
  }

  getAdUnitForContainerId(containerId: string) {
    // Logic to get the ad unit for a given container ID
    return this.containerAdUnitMap.get(containerId);
  }

  refreshAds(slots?: SlotUnion[], config?: AuctionConfig) {
    // Logic to refresh ads for the given slots and config
    let _slots: String[] = null;
    if (Array.isArray(slots)) {
      _slots = slots.map((slot) => this.getSlotContainerID(slot));
    } else {
      _slots = [...this.containerAdUnitMap.keys()];
    }
    this.do(() => {
      logger.log("Refreshing ads slots", _slots);
      window.freestar.refresh(_slots);
    });
  }

  display(slot: SlotUnion) {
    // Logic to display an ad for the given slot
    // no equivalent call in Freestar. no-op
  }

  getContainerIdsForAdUnit(adUnit: string): string[] {
    // Logic to get container IDs for a given ad unit
    return ["container-id-1", "container-id-2"];
  }

  loadAds(slots?: SlotUnion[], config?: AuctionConfig) {
    let elements = [];
    let _slots = slots;
    if (!_slots) {
      // get all slots
      _slots = [];
      for (let [containerId, adUnit] of this.containerAdUnitMap.entries()) {
        _slots.push(containerId);
      }
    }

    _slots.forEach((slot) => {
      const containerId = this.getSlotContainerID(slot);
      const adUnit = this.getAdUnitForContainerId(containerId);
      let placement = ConfigManager.getAdUnitConfig(adUnit)?.freestarPlacement;
      logger.log("### adUnit", adUnit, placement);

      if (!placement) {
        logger.error(`### No freestar placement found for ad unit: ${adUnit}`);
        return;
      }
      if (!adUnit) {
        logger.error(`### No ad unit found for container ID: ${containerId}`);
        return;
      }

      // if placement has a comma
      if (placement.includes(",")) {
        placement = this.getPlacementThatFitsInContainer(
          placement,
          containerId
        );
      }

      const targeting = TargetingManager.getSlotTargeting(containerId);
      elements.push({
        slotId: containerId,
        placementName: placement,
        targeting,
      });
    });
    if (elements.length > 0) {
      this.do(() => {
        //todo: ad targeting
        logger.log(`### Loading ads for elements:`, elements, "Slots:", _slots);
        window.freestar.newAdSlots(
          elements,
          null,
          TargetingManager.getGlobalTargeting()
        );
      });
    }
  }

  private getPlacementThatFitsInContainer(
    placement: any,
    container: string
  ): string {
    const placements = placement.split(",");
    const placmentSizes = placements.map((p) => {
      const sizes = p.match(this.placementSizeRegex);
      if (sizes) {
        return new ContainerSize(parseInt(sizes[1]), parseInt(sizes[2]));
      } else {
        logger.error(
          `### Invalid placement size: ${p} for ad unit: ${container}`
        );
      }
    });
    const containerSize = ContainerSizeManager.getContainerSize(container);
    let largestSize = null;

    if (containerSize) {
      const sizesThatFit = placmentSizes.filter((size) =>
        size.widthLessThanOrEqual(containerSize)
      );

      if (sizesThatFit.length === 0) {
        logger.error(
          `### No placement sizes that fit container: ${container} sizes: ${JSON.stringify(
            placmentSizes
          )}`
        );
        largestSize = ContainerSize.getSmallestWidthFromArray(placmentSizes);
      } else {
        largestSize = ContainerSize.getLargestWidthFromArray(sizesThatFit);
      }
    } else {
      // if container size is not available, use the smallest size
      largestSize = ContainerSize.getSmallestWidthFromArray(placmentSizes);
    }

    // find placement that matches the largest size
    const matchingPlacement = placements.find((p) =>
      p.includes(`${largestSize.width}x${largestSize.height}`)
    );
    if (!matchingPlacement) {
      logger.error(
        `### No matching placement found for container: ${container}`
      );
      return null;
    }
    logger.log("### Found matching placement", matchingPlacement);
    return matchingPlacement;
  }

  setDefaultAuctionConfig(config: AuctionConfig) {
    //non-op
  }

  useAdapters(adapterNames: AuctionAdapterName[]) {
    //non-op
  }

  do(fn: () => void): void {
    if (BlacklistManager.isUrlBlacklisted()) {
      try {
        fn();
      } catch (e) {
        logger.error("Error in Freestar do", e);
      }
      return;
    }
    window.freestar.queue.push(fn);
  }

  clearSlotsById(slotContainerIds: string[]): boolean {
    return true;
  }
  clearSlots(slots?: SlotUnion[]): boolean {
    return true;
  }

  enableDebug(): void {
    Logger.setDebugLogging(true);
  }

  async update(): Promise<void> {
    //non-op
  }

  /* Adds a new ad slot to the page
   *
   * @param adUnit The ad unit to use for the slot
   * @param containerId The id of the div to use as a container for the slot
   * @returns
   */
  async addSlot(adUnit: string, containerId: string): Promise<void> {
    this.containerAdUnitMap.set(containerId, adUnit);
  }
}

export default FreestarAdSystem;
