import injectScript from "./injectScript";
import Logger from "./Logger";

window.googletag = window.googletag || { cmd: [] };

// disable initial load so we can control ad requests
window.googletag.cmd.push(function () {
  window.googletag.pubads().disableInitialLoad();
  Logger.log("Disabled googletag auto loading");
});

export default () => window.googletag;
