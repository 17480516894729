import Logger from "../../Logger";
import RuleAction from "./RuleAction";
import BlacklistManager from "../../BlacklistManager";

export default class DisableAdsRuleAction extends RuleAction {
  constructor(params: any) {
    super(params);
  }
  perform(): void {
    Logger.log("DisableAdsRuleAction.perform");
    BlacklistManager.disableAds();
  }
}
