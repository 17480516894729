import Auction from ".";

export default class AuctionAdapter {
  auctionComplete: boolean = false;
  auction: Auction;
  config: Object;
  name: String;

  constructor(a: Auction, config: Object, name: String) {
    this.auction = a;
    this.config = config;
    this.name = name;
  }

  getBids(slots, containers) {}

  complete() {
    this.auctionComplete = true;
    this.auction.adapterComplete(this);
  }
}
