import Logger from "./Logger";
import CmpManager from "./CmpManager";
import ConfigManager from "./ConfigManager";

class LinkManager {
  PP_URL: string = "https://maplemedia.io/privacy/";
  TOS_URL: string = "https://maplemedia.io/terms-of-service/";
  privacyPolicyOverrideUrl: string = null;

  linkElements: Array<HTMLElement> = [];
  mutationObservers: Map<string, MutationObserver> = new Map();

  searchForNewLinks() {
    let elements = document.querySelectorAll("[data-mastodon-link]");
    elements?.forEach((e: HTMLElement) => {
      if (this.linkElements.includes(e)) {
        return;
      }
      this.linkElements.push(e);
      const { dataset } = e;
      const { mastodonLink } = dataset;
      Logger.log("Found new link element", e, dataset, mastodonLink);
      this.setupLink(e, mastodonLink);
    });

    // handle `href=#dsar` links
    elements = document.querySelectorAll(`[href="#dsar"]`);
    elements?.forEach((e: HTMLElement) => {
      if (this.linkElements.includes(e)) {
        return;
      }
      this.linkElements.push(e);
      const { dataset } = e;
      const { mastodonLink } = dataset;
      Logger.log("Found new link element", e, dataset, mastodonLink);
      this.setupLink(e, "dsar");
    });
  }

  getBarePrivacyPolicyUrl(): string {
    return this.privacyPolicyOverrideUrl || this.PP_URL;
  }

  getPrivacyPolicyUrl(): string {
    Logger.log(this.privacyPolicyOverrideUrl, this.PP_URL);
    let params = this.getPrivacyPolicyParams();
    let url = this.getBarePrivacyPolicyUrl();
    return `${url}?dsar_url=${encodeURIComponent(
      CmpManager.getDsarUrl() + "?" + params
    )}`;
  }

  getPrivacyPolicyParams(): string {
    let params = {
      referer: window.location.href,
      source: "Mastodon",
      env: ConfigManager.env,
      site: ConfigManager.site,
    };
    if (ConfigManager.options?.userId) {
      params["user_id"] = ConfigManager.options?.userId;
    }
    return new URLSearchParams(params).toString();
  }

  setupLink(e, type) {
    switch (type) {
      case "privacy_center":
        e.addEventListener("click", (event) => {
          event.preventDefault();
          Logger.log("Preference Center Link Clicked", e, type);
          CmpManager.openPreferenceCenter();
        });
        break;
      case "tos":
        e.addEventListener("click", (event) => {
          event.preventDefault();
          Logger.log("TOS Link Clicked", e, type);
          window.location.href = this.TOS_URL;
        });
        break;
      case "privacy_policy":
        e.addEventListener("click", (event) => {
          event.preventDefault();
          Logger.log("Privacy Policy Link Clicked", e, type);

          window.location.href = this.getPrivacyPolicyUrl();
        });
        break;
      case "dsar":
        e.addEventListener("click", (event) => {
          event.preventDefault();
          Logger.log("DSAR Link Clicked", e, type);
          let params = this.getPrivacyPolicyParams();
          const url = CmpManager.getDsarUrl("?" + params);
          Logger.log("Opening DSAR form", CmpManager.getTemplate(), url);
          window.location.href = url;
        });
        break;
      default:
        Logger.log("Unknown link type found", type);

        e.addEventListener("click", (event) => {
          Logger.log("Unknown link type clicked", e, type);
        });
    }
  }
}

export default new LinkManager();
