import ConfigManager from "./ConfigManager";
import Logger from "./Logger";
import TargetingManager from "./TargetingManager";

import ScreenDetector from "./ScreenDetector";
import {
  AuctionConfig,
  SlotUnion,
  AuctionAdapterName,
  DestroySlotsOptions,
  MastodonOptions,
  GeoData,
} from "./Types";
import DomAdManager from "./DomAdManager";

import UrlChangeManager from "./UrlChangeManager";
import CmpManager from "./CmpManager";
import LinkManager from "./LinkManager";
import ElementRuleManager from "./ElementRuleManager";
import GeolocationManager from "./GeolocationManager";
import FlippManager from "./FlippManager";
import UserIdManager from "./UserId/UserIdManager";
import AdSystem from "./AdSystem";
import AdSystemFactory from "./AdSystem/AdSystemFactory";
import BlacklistManager from "./BlacklistManager";
import DisabledAdSystem from "./AdSystem/DisabledAdSystem";

/**
 * Maple Media API for displaying ads on websites

## Integration

To integrate with Mastodon, add the following tags to the `<head>` of every page:

```html
<script
  async
  src="//mastodon.maplemedia.tech/mastodon_2.js"
  type="text/javascript"
></script>
<script type="text/javascript">
  window.Mastodon = window.Mastodon || [];
  Mastodon.push(function () {
    Mastodon.enableDebug(); // Enable debug output. Remove for production
    Mastodon.init("[SITE NAME]", "[prod|stage]"); // Replace values in []
  });
</script>
```

---

## Making calls to Mastodon API's

Mastodon should be loaded `async` to prevent its loading from slowing down the rest of the site loading. Because of
this, it is important to only call Mastodon API's after it is ready.
To ensure your calls to Mastodon only happen after Mastodon is ready, always wrap them in a {@link Mastodon.push}() call
like so:

```javascript
Mastodon.push(function () {
  // Put your calls to Mastodon API's here
});
```

### Referencing slots

When calling Mastodon's API's, you may reference a slot by it's div's `id` attribute.
Alternatively, you may also use the googletag `Slot` object if you have it. But referencing the div's `id` attribute
is usually the most convenient.

---

## Displaying Ads

It is recommended you use Mastodon's automatic adslot detection for displaying ads on a page. To do so, add a div
like so:

```html
<div data-mastodon-adunit="leader"></div>

<script>
  // add after all divs have been added to the dom
  Mastodon.push(() => Mastodon.update());
</script>
```

The {@link Mastodon.update}() call should automatically detect any divs with the `data-mastodon-adunit`
attribute, read the attribute's value, and use that as the adunit name. It will then attempt to load an ad into that
div.

In case any divs get added after this initial {@link Mastodon.update}() call, you should call
{@link Mastodon.update}() again to get Mastodon to recognize any newly-added ad containers.
You can run this whenever new ad containers might have been added to the DOM.

---

## Targeting

### Page-level Targeting

For page-level targeting, you can use {@link Mastodon.addGlobalTargeting}(). This accepts an object as a map, where the
keys are the targeting keys and the values are the targeting values to set. This object should be flat.

{@link addGlobalTargeting}() is additive. Calling it multiple times with new keys will add to the keys already set.

---

### Slot-level targeting

Slot-level targeting may be set automatically if using automatic adslot detection by adding a
`data-mastodon-targeting` attirubte to the slot's div.

key-value pairs should be separated by a semicolon (`;`), while keys and their values should be separated by an
equals `=` sign. Keys and values are trimmed so you may add whitespace between them.

Example:

```html
<div
  data-mastodon-adunit="grid_2"
  data-mastodon-targeting="foo=bar; zoo = bat; mykey=a string with spaces;"
></div>
```

---

## Controlling the size of ads

If you provide a `data-mastodon-width` attribute to container with the number of pixels wide the container should be,
Mastodon will do its best to only show ads that will fit within it. The only exception to this if there aren't any ad sizes that would fit,
Mastodon will try to show the smallest ad.

Mastodon watches this attribute for changes, so if this changes dynamically, mastodon will reload a new ad that should fit automatically.

For example, Mastodon will only show ads no wider than 500 pixels:

```html
<div data-mastodon-adunit="grid_2" data-mastodon-width="500"></div>
```

---

## Removing ads

If an ad slot needs to be removed from a page, call {@link Mastodon.destroySlots}([...slots])
This needs to be called before the slot elements are removed from the page.

If Called without an array of slots, {@link destroySlots} will remove all of the slots on the page.

If the slot is automatically tracked, calling {@link destroySlots} will also automatically remove it from the page.

See {@link searchForNewAdContainers} for more information.

---

## Links

Mastodon will automatically add click handlers to all links on the page.
To specify a link, add the `data-mastodon-link` attribute to an element and Mastodon will automatically had handlers to it.

Supported link types:
|data-mastodon-link value|Description|
|:----|:----|
|privacy_center|A link to open the One Trust Preference Center|
|dsar|A link to open the DSAR form|
|tos|Terms of Service|
|privacy_policy|Privacy Page|

---

## Flipp Grocery Integration

To add Flipp integration to a site, simply add a `<div>` element to the page with a `data-mastodon-flipp` attribute like so:

```html
<div data-mastodon-flipp></div>
```

To have it start in compact mode, add a `data-mastodon-start-compact` attribute:

```html
<div data-mastodon-flipp data-mastodon-start-compact="true"></div>
```

To make the unit automatically expand upon dwelling on it, use the `data-mastodon-dwell-expandable` attribute:

```html
<div data-mastodon-flipp data-mastodon-dwell-expandable="true"></div>
```

---

## Events

Mastodon will fire several lifecycle events.
To listen to an event, use the `addEventListener()` method:

```
Mastodon.addEventListener("skipLazyLoadAdSlot", (event) => {
  console.log("skipLazyLoadAdSlot", event);
});
```

Available events:

- `adRendered` - Fired when an ad is rendered
- `skipLazyLoadAdSlot` - Fired when a lazy-load ad slot it skipped due to no fill
- `initComplete` - Fired when initialization is complete

---

# Development

To install all dependencies, install yarn and run `yarn install`

## Test page with ads

To start a test page for development that includes all of the features of Mastodon, run `yarn start`

Then open your browser to http://localhost:1234/?utm_source=adops_qa_testing

## Test page without ads

To start a test page simulating a simple site without ads, run `yarn start-no-ads`

## Run unit tests

To run the suite of unit tests, run `yarn test`

## API Documentation

API documentation is generated based on the source code.

To build documentation, run `yarn docs`

To Test documentation locally, first run `yarn docs-watch` to build the docs and watch for changes.
Then run `yarn docs-start` to serve them. You can then view them by navigating to http://localhost:12345

 */
export class Mastodon extends EventTarget {
  private initialized: boolean = false;
  private taskQueue: any[] = [];
  private firstTaskRun: boolean = false;

  private taskCounter: number = 0;

  public adSystem: AdSystem;

  /**
   * Fired when Mastodon is initialized and ready to use
   * @event
   */
  static readonly INIT_COMPLETE = "initComplete";

  /**
   * Fired when an ad is rendered. Emits a {@link AdRenderedEvent}
   * @event
   */
  static readonly AD_RENDERED = "adRendered";

  /**
   * Fired when a lazy load ad slot is skipped. Emits a {@link SkipLazyLoadAdSlotEvent}
   * @event
   */
  static readonly SKIP_LAZY_LOAD_AD_SLOT = "skipLazyLoadAdSlot";

  /**
   * Fired when the CMP consent status changes. Emits a {@link CmpConsentChangedEvent}
   * @event
   */
  static readonly CMP_CONSENT_CHANGED = "cmpConsentChanged";

  constructor() {
    super();
    console.log("mastodon constructor");
    this.initialized = false;
    if (window.location.search.includes("mastodon_debug")) {
      Logger.setDebugLogging(true);
    }
  }

  /**
   * Initializes
   * @param site The name of the property or site
   * @param env The envronment name (stage, prod)
   * @param platform The platform the user is visiting from (mobile, desktop)
   */
  async init(
    site: string,
    env: string,
    platform?: string,
    options?: MastodonOptions
  ) {
    console.log("init");
    if (this.initialized) {
      Logger.warn("Mastodon has already been initialized. Ignoring init call!");
      return;
    }
    let start = Date.now();
    ConfigManager.options = { ...ConfigManager.options, ...options };
    Logger.hero();
    Logger.group("Initializing");
    Logger.log(
      "init params:",
      site,
      env,
      platform || ScreenDetector.desktopOrMobile()
    );
    if (!options?.config) {
      await ConfigManager.fetchConfig(
        site,
        env,
        platform || ScreenDetector.desktopOrMobile()
      );
    }
    if (BlacklistManager.isUrlBlacklisted()) {
      this.adSystem = new DisabledAdSystem();
    } else {
      this.adSystem = AdSystemFactory.createAdSystem(
        ConfigManager.getAdSystem()
      );
    }
    if (window.location.search.includes("mastodon_debug")) {
      this.enableDebug();
    }

    await Promise.all([
      CmpManager.init(),
      GeolocationManager.getGeoLocationDataAsync(),
    ]);

    UrlChangeManager.init();

    await ElementRuleManager.init();
    if (!BlacklistManager.isUrlBlacklisted()) {
      await this.adSystem.init();
    }
    // load CMP and GPT tags at once
    Logger.log("Mastodon.init() took", Date.now() - start, "ms");
    Logger.endGroup();
    this.dispatchEvent(new Event(Mastodon.INIT_COMPLETE));

    if (ConfigManager.hasAds()) {
      // timeout after 3 seconds, and execute next tasks if prebid and gpt are not loaded
      var adInitTimeout = setTimeout(() => {
        Logger.warn("Ad init timed out after 3 seconds. Executing next task.");
        this.initialized = true;
        this.executeNextTask();
      }, 3000);

      this.adSystem.do(() => {
        this.initialized = true;
        clearTimeout(adInitTimeout);
        Logger.log("Running queued tasks after init", this.taskQueue);
        this.executeNextTask();
      });
    } else {
      Logger.log("Running queued tasks after init", this.taskQueue);
      this.initialized = true;
      this.executeNextTask();
    }

    setTimeout(() => {
      this.update();
    }, 500);
  }

  /**
   * @hidden
   */
  checkInitialized() {
    if (!this.initialized) throw new Error("Mastodon not initialized yet!");
  }

  /**
   * adds the keys to global targeting
   * @param t
   */
  addGlobalTargeting(t: Object) {
    this.checkInitialized();
    TargetingManager.addGlobalTargeting(t);
  }

  /**
   * replaces all global targeting params with the new ones
   * @param t
   */
  replaceGlobalTargeting(t: Object) {
    this.checkInitialized();
    TargetingManager.replaceGlobalTargeting(t);
  }

  /**
   * Adds the keys in `t` to the `adUnit` config
   * @param slot the container id or slot object for the slot
   * @param t object container targeting keys and values
   */
  addSlotTargeting(slot: SlotUnion, t: object) {
    this.checkInitialized();
    TargetingManager.addSlotTargeting(slot, t);
  }

  /**
   * Replaces the `containerId` config with the keys and values in `t`
   * @param slot the container id or slot object for the slot
   * @param t object container targeting keys and values
   */
  replaceSlotTargeting(slot: SlotUnion, t: object) {
    this.checkInitialized();
    TargetingManager.replaceSlotTargeting(slot, t);
  }

  /**
   * Changes the platform
   * @param platform 'mobile' or 'desktop'
   */
  setPlatform(platform: string) {
    ConfigManager.setPlatform(platform);
  }

  /**
   * Enables debug logging
   */
  enableDebug() {
    Logger.log("Enabling debug logging");
    Logger.setDebugLogging(true);
    if (this.adSystem) this.adSystem.enableDebug();
  }

  /**
   * Adds a new ad slot to the page
   *
   * @param adUnit The ad unit to use for the slot
   * @param containerId The id of the div to use as a container for the slot
   * @returns
   */
  async addSlot(adUnit: string, containerId: string) {
    this.adSystem.addSlot(adUnit, containerId);
  }

  /**
   * Returns the full ad unit path for the specified ad unit
   * @param adUnit
   */
  getAdunitPath(adUnit: string): string {
    return ConfigManager.getAdUnitPath(adUnit);
  }

  /**
   * Returns the full Mastodon config object
   *
   */
  getFullConfig(): Object {
    return ConfigManager.configData;
  }

  /**
   * Initiates an auction to display(or refresh) the specified ad slots
   * @param slots The slot objects or container ids of the slots to load
   * @param config Optional config
   */
  loadAds(slots?: SlotUnion[], config?: AuctionConfig) {
    this.adSystem.loadAds(slots, config);
  }

  /**
   * Instructs the service to render the slot
   * @param slotId
   */
  display(slot: SlotUnion) {
    this.adSystem.display(slot);
  }

  /**
   * Pushes a javascript function to the queue for execution once mastodon is ready.
   * Functions pushed to the queue will be executed in the order they were pushed - FIFO.
   * @param {*} func
   */
  push(func: Function) {
    Logger.log("got push", func);
    if (!this.firstTaskRun) {
      Logger.log("executing first task");
      func();
      this.firstTaskRun = true;
    } else {
      this.taskQueue.push(func);
      if (this.initialized) {
        Logger.log("executing non-first task");
        this.executeNextTask();
      }
    }
  }

  /**
   * Returns an array of slot objects for the slot container ids.
   * @param slots Container ID of the slots
   */
  getSlotObjects(slots: SlotUnion[]): googletag.Slot[] {
    return this.adSystem.getSlotObjects(slots);
  }

  /**
   * Returns container ids for each ad slot for the specified adUnit
   * @param adUnit
   * @return array of container ids active for that adUnit
   */
  getContainerIdsForAdUnit(adUnit: string): string[] {
    return this.adSystem.getContainerIdsForAdUnit(adUnit);
  }

  getAdUnitForContainerId(containerId: string) {
    return this.adSystem.getAdUnitForContainerId(containerId);
  }

  /**
   * Clears all slots content for the given slot container ids
   * @param slotContainerIds Container IDs of the slots
   *
   */
  clearSlotsById(slotContainerIds: string[]): boolean {
    return this.adSystem.clearSlotsById(slotContainerIds);
  }

  /**
   * Clears all content for the given slots
   * @param slots The slot objects to clear
   * @returns
   */
  clearSlots(slots?: SlotUnion[]): boolean {
    return this.adSystem.clearSlots(slots);
  }

  /**
   * Destroys ad slots.
   * If the slot was tracked automatically by {@link Mastodon.update}() then it will also remove the slot div
   * from the DOM.
   *
   * If an array of slots is not provided, it will destroy all slots.
   * @param slots The slots to destroy
   */
  destroySlots(
    slots?: SlotUnion[],
    options: DestroySlotsOptions = { clearRefresh: true, removeFromDom: true }
  ): void {
    this.adSystem.destroySlots(slots, options);
  }

  /**
   * @hidden
   */
  executeNextTask() {
    let tasknumber = this.taskCounter++;
    const task = this.taskQueue.shift();
    Logger.log(`executeNextTask() called - task ${tasknumber}`, task);
    if (task) {
      try {
        task();
      } catch (e) {
        Logger.error(`Task ${tasknumber} threw an error`, task, e);
      }
      Logger.log(`task ${tasknumber} execution complete`);
      Logger.endGroup();
    } else {
      Logger.log(`task ${tasknumber} is falsy`);
    }
    if (this.taskQueue.length) this.executeNextTask();
  }

  /**
   * Sets the default configuration for all auctions
   * @param config
   */
  setDefaultAuctionConfig(config: AuctionConfig) {
    this.adSystem.setDefaultAuctionConfig(config);
  }

  /**
   * Sets the adapters to use for all auctions
   * @param adapterNames array of adapter names. Currently support 'pbjs' and 'amazon'
   */
  useAdapters(adapterNames: AuctionAdapterName[]) {
    this.adSystem?.useAdapters(adapterNames);
  }

  /**
   * Searches the DOM for new mastodon ad containers and shows ads for them.
   *
   * If using this feature, manually calling {@link Mastodon.addSlot}(), {@link Mastodon.display}(), {@link Mastodon.loadAds}() is NOT required.*
   *
   * Example: put a div on the page with the `data-mastodon-adunit` attribute on the page, with the name of the adunit as the value:
   * ```html
   * <div data-mastodon-adunit="leader"></div>
   * <script>Mastodon.push(()=>Mastodon.update())</script>
   * ```
   * You may specify an `id` for the div, or omit it. If omitted, Mastodon will generate an appropriate id for it.
   *
   * @deprecated Use `update()` instead
   */
  searchForNewAdContainers() {
    Logger.log(
      "Mastodon Deprecated: use Mastodon.update() instead of Mastodon.searchForNewAdContainers()"
    );
    this.update();
  }

  /**
   * Tells Mastodon to search the DOM for new ad containers, links, and process element rules.
   *
   * If using this feature, manually calling {@link Mastodon.addSlot}(), {@link Mastodon.display}(), {@link Mastodon.loadAds}() is NOT required.*
   *
   * Example: put a div on the page with the `data-mastodon-adunit` attribute on the page, with the name of the adunit as the value:
   * ```html
   * <div data-mastodon-adunit="leader"></div>
   * <script>Mastodon.push(()=>Mastodon.update())</script>
   * ```
   * You may specify an `id` for the div, or omit it. If omitted, Mastodon will generate an appropriate id for it.
   *
   */
  async update() {
    ElementRuleManager.performActions();
    LinkManager.searchForNewLinks();
    FlippManager.searchForNewAdContainers();

    await this.adSystem.update();
    DomAdManager.searchForNewAdContainers();
  }

  /**
   * Returns the version of Mastodon that is running
   */
  getVersion() {
    return process.env.MASTODON_VERSION;
  }

  /**
   *
   * @hidden
   * @param tasks
   */
  addExistingTasks(tasks) {
    tasks.forEach((t) => this.push(t));
  }

  /**
   * Updates config to restrict size of ad.
   *
   * Does not does not affect slots already added.
   * Use setSlotSizeAndReset() if ad is already displayed.
   *
   * @param slot
   * @param width
   * @param height
   */
  setSlotSize(slot: SlotUnion, width: number, height: number) {
    this.adSystem.setSlotSize(slot, width, height);
  }

  /**
   * Updates config to restrict size of ad. Destroys and rebuilds config and ad slot.
   * Does not reset if the new size doesn't change the sizes of ads at all.
   * Does not call loadAds() so that should be done manually.
   *
   * @param slot
   * @param width
   * @param height
   * @return Returns true if reset wa necesarry, false if no reset required.
   */
  setSlotSizeAndReset(slot: SlotUnion, width: number, height: number): boolean {
    return this.adSystem.setSlotSizeAndReset(slot, width, height);
  }

  /**
   * Sets a processor function that will get called for each new ad element discovered.
   * If the processor returns `false`, then the element will be skipped and an ad will not be rendered.
   * Skipped containers will have processor called again on subsequent calls to {@link Mastodon.update}()
   * @param processor
   */
  setDomElementProcessor(processor: (element: HTMLElement) => boolean = null) {
    DomAdManager.domElementProcessor = processor;
  }

  /**
   * returns the geolocation data for the user. May use data from OneTrust,
   * Mastodon, or overridden by the URL parameter `otgeo`
   */
  getGeoLocationData(): GeoData {
    return GeolocationManager.getGeoLocationData();
  }
  /**
   * asynchronously returns the geolocation data for the user. May use data from OneTrust,
   * Mastodon, or overridden by the URL parameter `otgeo`
   */
  getGeoLocationDataAsync(): Promise<GeoData> {
    return GeolocationManager.getGeoLocationDataAsync();
  }

  /**
   * returns the canonical geolocation data for the user directly from Mastodon
   */
  async getCanonicalGeoLocationData(): Promise<GeoData> {
    return GeolocationManager.getCanonicalGeoData();
  }

  /**
   * overrides the privacy policy URL for the user. This will be used instead of the default privacy policy URL.
   * @param url
   */
  overridePrivacyPolicyUrl(url: string) {
    Logger.log("Overriding privacy policy URL to", url);
    LinkManager.privacyPolicyOverrideUrl = url;
  }

  overrideGdprDsarUrl(url: string) {
    Logger.log("Overriding GDPR URL to", url);
    CmpManager.overrideGDPRUrl = url;
  }

  overrideCpraDsarUrl(url: string) {
    Logger.log("Overriding CPRA URL to", url);
    CmpManager.overrideCPRAUrl = url;
  }

  overrideGlobalDsarUrl(url: string) {
    Logger.log("Overriding Global URL to", url);
    CmpManager.overrideGlobalUrl = url;
  }

  /**
   * returns the DSAR URL for the user with url parameters set
   */
  getDsarUrl() {
    let params = LinkManager.getPrivacyPolicyParams();
    const url = CmpManager.getDsarUrl("?" + params);
    return url;
  }

  /**
   * returns the privacy policy URL for the user with url parameters set for dsar
   */
  getPrivacyPolicyUrl() {
    return LinkManager.getPrivacyPolicyUrl();
  }

  getRemoteConfig(key: string) {
    return ConfigManager.getRemoteConfig(key);
  }

  getRemoteConfigObject() {
    return ConfigManager.getRemoteConfigObject();
  }

  /**
   * Disables ads. Should be called before mastodon is initialized.
   */
  disableAds() {
    BlacklistManager.disableAds();
  }
}

export type MastodonUnion = [] | Mastodon;
export * from "./Types";
export * from "./Events";
export * from "./LazyLoadAdManager";
