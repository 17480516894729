import ConfigManager from "./ConfigManager";
import Logger from "./Logger";
import { cdnPath } from "./Utils";
import injectScript from "./injectScript";
import "../prebid.js";
import pbjs from "./pbjs";
import UserIdManager from "./UserId/UserIdManager";

class PrebidManager {
  processedAdUnits: string[] = [];

  do(func) {
    pbjs().que.push(func);
  }

  setupAdUnits() {
    let adUnits = ConfigManager.getPrebidAdUnitsConfig();
    //get new adunits
    adUnits = adUnits.filter(
      (adUnit) => !this.processedAdUnits.includes(adUnit.code)
    );
    //add new adunits to list of processed ones
    adUnits.forEach((adUnit) => this.processedAdUnits.push(adUnit.code));

    Logger.log("Adding adUnits to prebid", adUnits);
    this.do(() => {
      pbjs().addAdUnits(adUnits);
      Logger.log("prebid adunits added");
    });
  }

  removeSlot(containerId: string) {
    pbjs().removeAdUnit(containerId);
    // remove this container from list of processed units
    this.processedAdUnits = this.processedAdUnits.filter(
      (c) => c != containerId
    );
  }

  configure() {
    if (!ConfigManager.hasAds()) {
      Logger.log("Not loading prebid because ads are not enabled");
      return;
    }
    // injectScript(cdnPath("/prebid7.47.0.js"));
    this.do(() => {
      this.setupBucketSizing();
      this.configureUserId();
      pbjs().bidderSettings = {
        standard: {
          storageAllowed: true,
        },
      };
    });
  }

  configureUserId() {
    var config = UserIdManager.getPrebidConfiguration();
    if (!config) return;
    pbjs().setConfig({
      userSync: {
        userIds: config,
      },
    });
  }

  setupBucketSizing() {
    const customConfigObject = {
      buckets: [
        {
          max: 20,
          increment: 0.05, // $0.05 - $20 increments of $0.05
        },
        {
          max: 50,
          increment: 1, // $20-$50 increments of $1
        },
      ],
    };
    Logger.log("Setting bucket sizing to", customConfigObject);
    //set custom config object
    pbjs().setConfig({
      priceGranularity: customConfigObject,
      floors: {},
    });
  }
}

export default new PrebidManager();
