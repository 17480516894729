import ConfigManager from "./ConfigManager";
import Logger from "./Logger";

class BlacklistManager {
  regexes?: RegExp[] = null;
  blacklistAll: boolean = false;

  getUri() {
    return `${window.location.pathname}${window.location.search}`;
  }

  hasMatch(str: string) {
    const r = this.getBlacklistRegexs() || [];
    for (const regex of r) {
      if (regex.test(str)) {
        return true;
      }
    }
    return false;
  }

  disableAds() {
    Logger.log("Disabling ads");
    this.blacklistAll = true;
  }

  isUrlBlacklisted() {
    if (this.blacklistAll) {
      return true;
    }
    if (this.hasMatch(this.getUri())) {
      return true;
    }
    return false;
  }

  getBlacklistRegexs(): Array<RegExp> {
    if (this.regexes != null) return this.regexes;
    const urls = ConfigManager.configData["blacklistUrls"];
    if (!urls) return [];
    const regexStrings = urls.split("\n").filter((line) => line.trim() != "");
    this.regexes = regexStrings.map((s) => new RegExp(s));
    return this.regexes;
  }
}

export default new BlacklistManager();
