import PrebidManager from "./PrebidManager";
import Logger from "./Logger";
import pbjs from "./pbjs";
import ConfigManager from "./ConfigManager";
import { injectScriptAsync } from "./injectScript";
import LinkManager from "./LinkManager";
import GeolocationManager from "./GeolocationManager";
import { mastodon } from "./Utils";
import { Mastodon } from "./Mastodon";

class CmpManager {
  GDPR_DSAR_URL =
    "https://privacyportal.onetrust.com/webform/c86e34a8-8ec1-4049-8c12-f734ced17f34/ea20a880-ec76-41f6-a935-b56f44ede6c0";
  CPRA_DSAR_URL =
    "https://privacyportal.onetrust.com/webform/c86e34a8-8ec1-4049-8c12-f734ced17f34/11e13241-6476-4838-a7fb-272b537e9dd3";
  GLOBAL_DSAR_URL =
    "https://privacyportal.onetrust.com/webform/c86e34a8-8ec1-4049-8c12-f734ced17f34/a6ddece6-12dc-4d1e-b23d-cdc9dc83086c";
  overrideGDPRUrl: string = null;
  overrideCPRAUrl: string = null;
  overrideGlobalUrl: string = null;
  overrideAllDsarUrl: string = null;

  active: boolean = false;
  template: string | null = null;

  async init() {
    this.handleParams();
    this.setupCrossDomain();
    await this.injectTags();
    this.configure();
  }

  handleParams() {
    var dsarUrlParam = ConfigManager.getUrlParam("dsar_url");
    Logger.log("Found dsar_url param", dsarUrlParam);

    if (dsarUrlParam) {
      this.overrideAllDsarUrl = dsarUrlParam;
    }
  }

  getGdprDsarUrl() {
    Logger.log(
      "getGdprDsarUrl",
      this.overrideAllDsarUrl,
      this.overrideGDPRUrl,
      this.GDPR_DSAR_URL
    );
    return (
      this.overrideAllDsarUrl || this.overrideGDPRUrl || this.GDPR_DSAR_URL
    );
  }

  getCpraDsarUrl() {
    return (
      this.overrideAllDsarUrl || this.overrideCPRAUrl || this.CPRA_DSAR_URL
    );
  }

  getGlobalDsarUrl() {
    return (
      this.overrideAllDsarUrl || this.overrideGlobalUrl || this.GLOBAL_DSAR_URL
    );
  }

  configure() {
    if (!this.active) {
      Logger.log("CMP Manager not active. Skipping configuration.");
      return;
    }

    PrebidManager.do(() => {
      Logger.log("Setting up CMP Manager for Prebid");

      pbjs().setConfig({
        consentManagement: {
          gdpr: {
            cmpApi: "iab",
            timeout: 1500,

            defaultGdprScope: false,
            rules: [
              {
                purpose: "basicAds",
                enforcePurpose: false,
                enforceVendor: false,
              },
            ],
          },
          usp: {
            timeout: 1500,
            cmpApi: "iab",
          },
        },
      });
    });

    //this will be called once CMP is loaded
    window.OptanonWrapper = () => {
      Logger.log("CMP loaded. ");
      // check rules once CMP is loaded

      mastodon().push(() => {
        if (!Array.isArray(window.Mastodon)) window.Mastodon.update();
      });

      this.handleOtUrlParams();
      this.updateCookieLinks();

      window.OneTrust.OnConsentChanged(function () {
        Logger.log("CMP consent changed. Refreshing ads");

        mastodon().loadAds();
        mastodon().dispatchEvent(new Event(Mastodon.CMP_CONSENT_CHANGED));
      });
    };
  }

  async injectTags() {
    var domainCode: string = ConfigManager.getOneTrustDataDomainScript();
    var domainCodeParam = domainCode;
    if (!domainCode) {
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
    }
    this.active = true;

    if (ConfigManager.env == "stage") {
      domainCodeParam = `${domainCode}-test`;
    }

    // ensure we have geo data
    await GeolocationManager.getGeoLocationDataAsync();

    // only inject tcf stub if GDPR
    if (this.getTemplate() == "GDPR") {
      Logger.log("Injecting TCF stub");
      await injectScriptAsync(
        `https://cdn.cookielaw.org/consent/tcf.stub.js`,
        {}
      );
    }

    await injectScriptAsync(
      `https://cdn.cookielaw.org/consent/${domainCode}/otSDKStub.js`,
      {
        "data-domain-script": domainCodeParam,
        charset: "UTF-8",
      }
    );
    if (this.getTemplate() == "CPRA") {
      Logger.log("Injecting CPRA stub");
      await injectScriptAsync(
        `https://cdn.cookielaw.org/opt-out/otCCPAiab.js`,
        {
          "ccpa-opt-out-geo": "us",
          "ccpa-opt-out-ids": "C0004,C0002",
          "ccpa-opt-out-lspa": "false",
        }
      );
    }

    Logger.log("CMP scripts injected and loaded", window.OnetrustActiveGroups);
  }

  setupCrossDomain() {
    if (
      ConfigManager.options.crossDomainToken != null ||
      ConfigManager.options.userId != null
    ) {
      window.OneTrust = {
        dataSubjectParams: {
          id: ConfigManager.options.userId,
          isAnonymous: false,
          token: ConfigManager.options.crossDomainToken,
        },
      };
      Logger.log("setup cross domain:", window.OneTrust);
    }
  }

  getTemplate(): string {
    if (this.template != null) {
      return this.template;
    }
    var geo = GeolocationManager.getGeoLocationData();
    if (geo == null) {
      return null;
    }
    if (geo.countryCode.toLocaleLowerCase() == "us") {
      if (/^(ca|ct|va|co|ut|tx|or|wa)$/i.test(geo.stateCode)) {
        this.template = "CPRA";
      } else {
        this.template = "GLOBAL";
      }
      return this.template;
    }

    var regex =
      /^(at|be|bg|hr|cy|cz|dk|ee|fi|fr|de|gr|hu|ie|it|lv|lt|lu|mt|nl|pl|pt|ro|sk|si|es|se|gb|li|no|is)$/i;
    if (regex.test(geo.countryCode)) {
      this.template = "GDPR";
      return this.template;
    }
    this.template = "GLOBAL";
    return this.template;
  }

  updateCookieLinks() {
    var links = document.querySelectorAll(
      `[href="${LinkManager.getBarePrivacyPolicyUrl()}"]`
    );

    links.forEach((link) => {
      link.attributes["href"].value = LinkManager.getPrivacyPolicyUrl();
      Logger.log("updated link", link);
    });
  }

  openPreferenceCenter() {
    window.OneTrust.ToggleInfoDisplay();
  }

  handleOtUrlParams() {
    let preferenceCenter = ConfigManager.getUrlParam("preferenceCenter");
    Logger.log("preferenceCenter", preferenceCenter);
    if (preferenceCenter) {
      this.openPreferenceCenter();
    }
  }

  getDsarUrl(suffix: string = null) {
    const template = this.getTemplate();
    let url = null;
    switch (template) {
      case "CPRA":
        url = this.getCpraDsarUrl();
        break;
      case "GDPR":
        url = this.getGdprDsarUrl();
        break;
      case "GLOBAL":
        url = this.getGlobalDsarUrl();
        break;
      default:
        Logger.error("Cannot open DSAR form for template", template);
    }
    if (url && suffix) {
      if (!this.overrideAllDsarUrl) {
        url += suffix;
      }
    }
    return url;
  }
}

export default new CmpManager();
