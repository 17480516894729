import ConfigManager from "../ConfigManager";
import Logger from "../Logger";
import BaseUserIdProvider from "./providers/BaseUserIdProvider";
import Id5Provider from "./providers/Id5Provider";
import Uid2Provider from "./providers/Uid2Provider";
import LiveRampProvider from "./providers/LiveRampProvider";

class UserIdManager {
  providers: BaseUserIdProvider[] = [
    Id5Provider,
    Uid2Provider,
    LiveRampProvider,
  ];
  emailMD5?: string;
  emailSHA256?: string;
  emailSHA1?: string;
  userAgent?: string;
  fullUrl?: string;
  PROVIDER_TIMEOUT = 1000;
  initialized = false;

  async init() {
    this.emailMD5 = ConfigManager.options.emailMD5;
    this.emailSHA256 = ConfigManager.options.emailSHA256;
    this.emailSHA1 = ConfigManager.options.emailSHA1;
    this.userAgent = navigator.userAgent;
    this.fullUrl = window.location.href;

    if (!this.emailMD5 && !this.emailSHA256 && !this.emailSHA1) {
      Logger.log(
        "No email hashes to send to Id Services. Skipping UserIdManager initialization."
      );
      return;
    }
    if (this.initialized) {
      Logger.log("UserIdManager already initialized. Skipping.");
      return;
    }
    this.initialized = true;

    Logger.log(
      "Initializing UserId Providers",
      this.emailMD5,
      this.emailSHA256,
      this.emailSHA1,
      this.userAgent
    );

    await Promise.all(this.providers.map((provider) => provider.init()));
    // for (const provider of this.providers) {
    //   await provider.init();
    // }
    Logger.log("Configuring UserId Providers");

    await Promise.all(this.providers.map((provider) => provider.configure()));
  }

  getPrebidConfiguration(): Object[] | null {
    if (!this.initialized) {
      Logger.log("UserIdManager not initialized. Skipping.");
      return [];
    }
    return this.providers.map((provider) => provider.getPrebidConfiguration());
  }
}

export default new UserIdManager();
