import config from "./ConfigManager";
import Logger from "./Logger";
import { SlotUnion } from "./Types";
import { mastodon } from "./Utils";

class TargetingManager {
  globalTargeting: Object = {};
  slotTargeting: Object = {};

  /**
   * adds the keys to global targeting
   * @param t
   */
  addGlobalTargeting(t: Object) {
    Logger.log("Adding global targeting", t);
    this.globalTargeting = { ...this.globalTargeting, ...t };
  }

  /**
   * replaces all global targeting params with the new ones
   * @param t
   */
  replaceGlobalTargeting(t: Object) {
    Logger.log("Replacing global targeting", t);
    this.globalTargeting = { ...t };
  }

  getGlobalTargeting() {
    return this.globalTargeting;
  }

  /**
   * Adds the keys in `t` to the `containerId` config
   * @param containerId string
   * @param t
   */
  addSlotTargeting(slot: SlotUnion, t: Object) {
    const containerId = mastodon().adSystem.getSlotContainerID(slot);
    Logger.log("Adding slot targeting", containerId, t);
    if (!this.slotTargeting[containerId]) this.slotTargeting[containerId] = {};
    this.slotTargeting[containerId] = {
      ...this.slotTargeting[containerId],
      ...t,
    };
  }

  /**
   * Replaces the `containerId` config with the keys and values in `t`
   * @param containerId
   * @param t
   */
  replaceSlotTargeting(slot: SlotUnion, t: Object) {
    const containerId = mastodon().adSystem.getSlotContainerID(slot);
    Logger.log("Replacing slot targeting", containerId, t);
    this.slotTargeting[containerId] = t;
  }

  getSlotTargeting(slot: SlotUnion) {
    const containerId = mastodon().adSystem.getSlotContainerID(slot);
    return this.slotTargeting[containerId];
  }
}

export default new TargetingManager();
