import Auction from ".";
import Logger from "../../../Logger";
import pbjs from "../../../pbjs";
import PrebidManager from "../../../PrebidManager";
import { BidBackHandler, PrebidAdapterConfig } from "../../../Types";
import AuctionAdapter from "./AuctionAdapter";

export default class PrebidAuctionAdapter extends AuctionAdapter {
  bidsBackHandler: BidBackHandler;
  constructor(auction: Auction, config: PrebidAdapterConfig) {
    super(auction, config, "pbjs");
    this.bidsBackHandler = config?.bidsBackHandler;
  }

  getBids(slots, containers): void {
    Logger.log("Prebid - adapter starting");
    PrebidManager.setupAdUnits();
    PrebidManager.do(() => {
      pbjs().requestBids({
        timeout: 3000,
        adUnitCodes: containers,
        bidsBackHandler: (bids) => {
          Logger.log("Prebid - Bids back handler called", bids);
          if (this.bidsBackHandler) {
            Logger.log("Calling custom bid back handler");
            this.bidsBackHandler(bids);
            Logger.log("Prebid - Returned from custom bid back handler");
          } else {
            Logger.log("Prebid - setting targeting");
            pbjs().setTargetingForGPTAsync(containers);
          }
          this.complete();
        },
      });
    });
  }
}
