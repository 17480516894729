import ConfigManager from "../ConfigManager";

import {
  AuctionConfig,
  SlotUnion,
  AuctionAdapterName,
  DestroySlotsOptions,
} from "../Types";

import ContainerSizeManager, { ContainerSize } from "../ContainerSizeManager";
import Logger from "../Logger";

abstract class AdSystem {
  constructor() {
    Logger.log("AdSystem constructor");
  }

  /**
   * Destroys ad slots.
   * If the slot was tracked automatically by {@link Mastodon.update}() then it will also remove the slot div
   * from the DOM.
   *
   * If an array of slots is not provided, it will destroy all slots.
   * @param slots The slots to destroy
   */
  abstract destroySlots(
    slots?: SlotUnion[],
    options?: DestroySlotsOptions
  ): void;

  async init(): Promise<void> {}

  abstract getSlotContainerID(slot: SlotUnion): string;

  getSlotContainerIDs(slot: SlotUnion[]): string[] {
    return slot?.map((s) => this.getSlotContainerID(s));
  }

  abstract getSlotObject(slot: SlotUnion): googletag.Slot;

  abstract getSlotObjects(slots: SlotUnion[]): googletag.Slot[];

  /**
   * Updates config to restrict size of ad. Destroys and rebuilds config and ad slot.
   * Does not reset if the new size doesn't change the sizes of ads at all.
   * Does not call loadAds() so that should be done manually.
   *
   * @param slot
   * @param width
   * @param height
   * @return Returns true if reset wa necesarry, false if no reset required.
   */
  abstract setSlotSizeAndReset(
    slot: SlotUnion,
    width: number,
    height: number
  ): boolean;

  getAdUnitForContainerId(containerId: string) {
    return ConfigManager.containerIdToAdUnitMap.get(containerId);
  }

  /**
   * Updates config to restrict size of ad.
   *
   * Does not does not affect slots already added.
   * Use setSlotSizeAndReset() if ad is already displayed.
   *
   * @param slot
   * @param width
   * @param height
   */
  setSlotSize(slot: SlotUnion, width: number, height: number) {
    const containerId = this.getSlotContainerID(slot);
    ContainerSizeManager.setContainerSize(
      containerId,
      new ContainerSize(width, height)
    );
  }

  /**
   * Returns container ids for each ad slot for the specified adUnit
   * @param adUnit
   * @return array of container ids active for that adUnit
   */
  abstract getContainerIdsForAdUnit(adUnit: string): string[];

  /**
   * Instructs the service to render the slot
   * @param slotId
   */
  abstract display(slot: SlotUnion);

  /**
   * Initiates an auction to display(or refresh) the specified ad slots
   * @param slots The slot objects or container ids of the slots to load
   * @param config Optional config
   */
  abstract loadAds(slots?: SlotUnion[], config?: AuctionConfig);

  abstract refreshAds(slots?: SlotUnion[], config?: AuctionConfig);

  /**
   * Sets the default configuration for all auctions
   * @param config
   */
  abstract setDefaultAuctionConfig(config: AuctionConfig);

  /**
   * Sets the adapters to use for all auctions
   * @param adapterNames array of adapter names. Currently support 'pbjs' and 'amazon'
   */
  abstract useAdapters(adapterNames: AuctionAdapterName[]);

  abstract do(fn: () => void): void;

  /**
   * Clears all slots content for the given slot container ids
   * @param slotContainerIds Container IDs of the slots
   *
   */
  abstract clearSlotsById(slotContainerIds: string[]): boolean;

  /**
   * Clears all content for the given slots
   * @param slots The slot objects to clear
   * @returns
   */
  abstract clearSlots(slots?: SlotUnion[]): boolean;

  abstract enableDebug(): void;

  /**
   * Adds a new ad slot to the page
   *
   * @param adUnit The ad unit to use for the slot
   * @param containerId The id of the div to use as a container for the slot
   * @returns
   */
  abstract addSlot(adUnit: string, containerId: string): Promise<void>;

  abstract update(): Promise<void>;
}

export default AdSystem;
