import Logger from "../../Logger";
import RuleAction from "./RuleAction";

interface ScriptParams {
  script?: string;
}

class ExecuteScriptRuleAction extends RuleAction {
  constructor(params: any) {
    super(params);
  }

  perform(): void {
    if (this.parameters.script) {
      Logger.log(
        "ExecuteScriptRuleAction.perform executing:",
        this.parameters.script
      );
      try {
        eval(this.parameters.script);
      } catch (e) {
        Logger.error("ExecuteScriptRuleAction.perform error", e);
      }
    } else {
      Logger.error("ExecuteScriptRuleAction.perform no script provided.");
    }
  }
}
export default ExecuteScriptRuleAction;
