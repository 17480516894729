import { LazyLoadElement } from "./LazyLoadAdManager";
/**
 * Event fired when a lazy load ad slot is skipped
 *
 * @event
 * @categrory Events
 *
 */
export class SkipLazyLoadAdSlotEvent extends Event {
  constructor(
    /**
     * The slot id of the slot that was rendered
     */
    public adSlotId: string,

    /**
     * The lazy load element that was skipped
     */

    public lazyLoadElement: LazyLoadElement
  ) {
    super("skipLazyLoadAdSlot");
  }
}

/**
 * Event fired when an ad is rendered. If there is no fill, isEmpty will be true
 * @event
 * @categrory Events
 */
export class AdRenderedEvent extends Event {
  constructor(
    /**
     * The slot object that was rendered
     */
    public adSlot: googletag.Slot,

    /**
     * The slot id of the slot that was rendered
     */
    public adSlotId: string,

    /**
     * Whether the slot was empty or not (no ad was rendered)
     */
    public isEmpty: boolean
  ) {
    super("adRendered");
  }
}
