import BlacklistManager from "./BlacklistManager";
import ConfigManager from "./ConfigManager";
import Logger from "./Logger";
import injectScript from "./injectScript";

/**
 * To add Flipp integration to a site, simply add a `<div>` element to the page with a `data-mastodon-flipp` attribute like so:
 *
 * ```html
 * <div data-mastodon-flipp></div>
 * ```
 *
 * To have it start in compact mode, add a `data-mastodon-start-compact` attribute:
 *
 * ```html
 * <div data-mastodon-flipp data-mastodon-start-compact="true"></div>
 * ```
 *
 * To make the unit automatically expand upon dwelling on it, use the `data-mastodon-dwell-expandable` attribute:
 *
 * ```html
 * <div data-mastodon-flipp data-mastodon-dwell-expandable="true"></div>
 * ```
 */
class FlippManager {
  adElements: Map<string, HTMLElement> = new Map();
  mutationObservers: Map<string, MutationObserver> = new Map();
  elementIdCounter: number = 0;
  domElementProcessor: (element: HTMLElement) => boolean = null;
  checkingContainers: boolean = false;
  hasCheckWaiting: boolean = false;

  async searchForNewAdContainers() {
    const flippZoneId = ConfigManager.getFlippZoneId();

    if (!flippZoneId) {
      Logger.log("No Flipp Zone Id found. Skipping Flipp integration");
      return;
    }
    if (this.checkingContainers) {
      this.hasCheckWaiting = true;
      Logger.log("Already checking for new ad containers. Waiting...");
      return;
    }
    this.checkingContainers = true;
    Logger.log("Seaching for new ad containers");
    let elements = document.querySelectorAll("[data-mastodon-flipp]");
    const newAdContainers: Array<string> = [];

    if (BlacklistManager.isUrlBlacklisted()) {
      Logger.log("Url is blacklisted. Skipping Flipp integration");

      this.checkingContainers = false;
      return;
    }

    // inject flipp script if we have flipp elements on the page
    if (elements.length > 0) {
      // inject flipp integration script
      this.injectFlippScript();
    }

    for (var element of Array.from(elements)) {
      let e = element as HTMLElement;

      const { dataset } = e;
      const { mastodonStartCompact = false, mastodonDwellExpandable = true } =
        dataset;

      if (!e.id) {
        e.id = `mastodon_flipp-${this.elementIdCounter++}`;
      }

      if (!this.adElements.has(e.id)) {
        this.adElements.set(e.id, e);
        Logger.log(
          "registering new flipp container",
          e,
          `#${e.id}`,
          "Maple Media Standard",
          1275485,
          [flippZoneId],
          {
            // startCompact: mastodonStartCompact,
            // dwellExpandable: mastodonDwellExpandable,
          }
        );
        window.flippxp.run.push(function () {
          window.flippxp.registerSlot(
            `#${e.id}`,
            "Maple Media Standard",
            1275485,
            [flippZoneId],
            {
              // startCompact: mastodonStartCompact,
              // dwellExpandable: mastodonDwellExpandable,
            }
          );
        });
      }
    } // for each element

    this.checkingContainers = false;
    if (this.hasCheckWaiting) {
      this.hasCheckWaiting = false;
      this.searchForNewAdContainers();
    }
  }

  injectFlippScript() {
    if (window.flippxp) {
      return;
    }
    injectScript(
      "https://cdn-gateflipp.flippback.com/tag/js/flipptag.js?site_id=1275485",
      {
        async: true,
      }
    );
    window.flippxp = window.flippxp || { run: [] };
  }
}

export default new FlippManager();
