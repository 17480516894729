import ConfigManager from "./ConfigManager";
import Logger from "./Logger";

class UrlChangeManager {
  lastPath: string;
  monitoredAdunits: Object[];

  init() {
    this.lastPath = window.location.pathname;
    const adUnits = ConfigManager.getAllAdUnitConfigs();

    this.monitoredAdunits = Object.keys(adUnits).filter(
      (k) => !!adUnits[k].refreshOnUrlChange
    );

    Logger.log("Monitoring adUnits for URL change", this.monitoredAdunits);
    if (this.monitoredAdunits.length) {
      setInterval(() => this.checkForChange(), 1000);
    }
  }

  checkForChange() {
    if (window.location.pathname != this.lastPath) {
      Logger.log(
        "Detected URL Change",
        window.location.pathname,
        this.lastPath
      );
      this.lastPath = window.location.pathname;
      if (!Array.isArray(window.Mastodon)) {
        const containers = this.getContainers();
        if (containers?.length) {
          Logger.log(
            "Reloading containers after URL change",
            containers,
            this.monitoredAdunits
          );
          window.Mastodon.loadAds(containers);
        }
      }
    }
  }

  getContainers() {
    let containers = [];
    this.monitoredAdunits.forEach((au) => {
      const c = ConfigManager.getContainerIdsForAdUnit(au);
      if (c) {
        containers = [...containers, ...c];
      }
    });
    return containers;
  }
}
export default new UrlChangeManager();
