import ConfigManager from "./ConfigManager";
import Logger from "./Logger";
import { GeoData } from "./Types";

class GeolocationManager {
  geoData: GeoData | null = null;
  promiseResolvers: any[] = [];
  fetching = false;

  constructor() {
    let geoParam = ConfigManager.getUrlParam("otgeo");
    Logger.log("got geo param", geoParam);

    if (geoParam) {
      this.setFromParam(geoParam);
    } else {
      this.loadGeoLocationDataFromSession();
      if (this.geoData == null) {
        this.retrieveGeoData();
      }
    }
  }

  setFromParam(geoParam: string) {
    let [country, state] = geoParam.split(",");

    this.geoData = {
      countryCode: country,
      stateCode: state,
      source: "param",
    };
    Logger.log("got geo data from param", this.geoData);
  }

  async retrieveGeoData() {
    try {
      this.fetching = true;
      let response = await window.fetch(
        "https://mastodon.maplemedia.tech/geolocation.json"
      );
      let data = await response.json();

      this.geoData = {
        countryCode: data.country,
        stateCode: data.region,
        countryName: data.countryName,
        stateName: data.regionName,
        postalCode: data.postalCode,
        timezone: data.timeZone,
        city: data.city,
        source: "mastodon",
      };
      Logger.log("got geo data", this.geoData);

      this.fetching = false;
      // save to session
      this.saveGeoLocationDataToSession();
      // resolve promises
      this.promiseResolvers.forEach((resolve) => resolve(this.geoData!));
    } catch (e) {
      Logger.error("Error getting geo data", e);
    }
  }

  /**
   *
   * @returns GeoData from mastodon
   */
  async getCanonicalGeoData(): Promise<GeoData> {
    if (this.geoData != null && this.geoData.source == "mastodon") {
      return this.geoData;
    }
    await this.retrieveGeoData();
    return this.geoData!;
  }

  saveGeoLocationDataToSession() {
    sessionStorage.setItem("geoData", JSON.stringify(this.geoData));
  }

  loadGeoLocationDataFromSession() {
    let geoData = sessionStorage.getItem("geoData");
    if (geoData) {
      this.geoData = JSON.parse(geoData);
    }
  }

  async getGeoLocationDataAsync(): Promise<GeoData> {
    if (this.geoData) {
      return this.geoData;
    }

    if (this.fetching) {
      return new Promise((resolve) => {
        this.promiseResolvers.push(resolve);
      });
    }

    return this.getGeoLocationData();
  }

  /**
   * @returns GeoData from OneTrust, Mastodon or param
   */
  getGeoLocationData(): GeoData {
    if (this.geoData != null) {
      return this.geoData;
    }

    if (window?.OneTrust?.geolocationResponse) {
      this.geoData = {
        ...window.OneTrust.geolocationResponse,
        source: "onetrust",
      };
      this.saveGeoLocationDataToSession();
      return this.geoData;
    }

    if (window.OneTrust?.getGeolocationData) {
      var data = window.OneTrust.getGeolocationData();
      this.geoData = {
        countryCode: data.country,
        stateCode: data.state,
        source: "onetrust",
      };
      this.saveGeoLocationDataToSession();
      return this.geoData;
    }

    Logger.log("GeolocationRuleType.getGeoLocationData no geo data available");
  }
}

export default new GeolocationManager();
