import AdSystem from ".";
import { AdSystemType } from "../Types";
import FreestarAdSystem from "./FreestarAdSystem";
import GamAdSystem from "./GamAdSystem";

class AdSystemFactory {
  static createAdSystem(adSystem: AdSystemType): AdSystem {
    switch (adSystem) {
      case "gam":
        return new GamAdSystem();
      case "freestar":
        return new FreestarAdSystem();
      default:
        throw new Error(`AdSystem ${adSystem} not supported`);
    }
  }
}

export default AdSystemFactory;
