export default class BaseUserIdProvider {
  constructor() {}

  async init(): Promise<void> {}

  async configure(): Promise<void> {}

  async getUserId(): Promise<void> {}

  // returns configuration object for prebid
  getPrebidConfiguration(): Object {
    return {};
  }
}
