import Logger from "./Logger";

const injectScript = (src, attributes = null, onLoad = null) => {
  const script = document.createElement("script");
  script.src = src;
  if (attributes != null) {
    Object.keys(attributes).forEach((key) => {
      script.setAttribute(key, attributes[key]);
    });
  }
  if (onLoad != null) {
    script.onload = onLoad;
  }
  document.head.appendChild(script);
};
export default injectScript;

export const injectScriptAsync = (src, attributes = null) => {
  const start = Date.now();
  Logger.log("injectScriptAsync", src, attributes);
  return new Promise<void>((resolve, reject) => {
    injectScript(src, attributes, () => {
      Logger.log(
        "injectScriptAsync",
        src,
        "loaded in ",
        Date.now() - start,
        "ms"
      );
      resolve();
    });
  });
};

export const injectLinkTag = (href, rel, attributes = null) => {
  const link = document.createElement("link");
  link.href = href;
  link.rel = rel;
  if (attributes != null) {
    Object.keys(attributes).forEach((key) => {
      link.setAttribute(key, attributes[key]);
    });
  }
  document.head.appendChild(link);
};
