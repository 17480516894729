import Logger from "../../Logger";
import RuleAction from "./RuleAction";

export default class HideRuleAction extends RuleAction {
  constructor(params: any) {
    super(params);
  }
  performOnElement(element: HTMLElement): void {
    Logger.log("HideRuleAction.performOnElement", element);
    element.style.display = "none";
  }
}
