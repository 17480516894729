import googletag from "../../../googletag";
import GoogletagManager from "../../../GoogletagManager";
import Logger from "../../../Logger";

import {
  AuctionAdapterName,
  AuctionCompleteHandler,
  AuctionConfig,
  SlotUnion,
} from "../../../Types";
import AuctionAdapter from "./AuctionAdapter";
import PrebidAuctionAdapter from "./PrebidAuctionAdapter";
import AmazonAuctionAdapter from "./AmazonAuctionAdapter";
import BlacklistManager from "../../../BlacklistManager";
import UserIdManager from "../../../UserId/UserIdManager";

/**
 * The main class for running an auction.
 * This is only used by the GAM ad system.
 */
export default class Auction {
  slots: SlotUnion[];
  config: AuctionConfig;
  adapters: AuctionAdapter[];
  onCompleteHandler: AuctionCompleteHandler;
  auctionComplete: boolean = false;
  timeoutTimer: any;

  constructor(slots?: SlotUnion[], config?: AuctionConfig) {
    if (slots) this.slots = slots;
    let adapterNames: AuctionAdapterName[] = ["pbjs"];

    if (config) {
      this.config = config;
      this.onCompleteHandler =
        config.onCompleteHandler || config.bidsBackHandler || null;
      if (config.adapters) {
        adapterNames = config.adapters;
      }
    }

    this.adapters = adapterNames.map((adapterName): AuctionAdapter => {
      switch (adapterName) {
        case "pbjs":
          return new PrebidAuctionAdapter(this, this.config?.pbjs);
          break;
        case "amazon":
          return new AmazonAuctionAdapter(this, this.config?.amazon);
          break;
        default:
          throw new Error(`Invalid adapter name ${adapterName}`);
      }
    });
  }

  private getSlots() {
    return this.slots?.map((slot) =>
      typeof slot == "object" ? slot : GoogletagManager.getSlot(slot) || null
    );
  }

  private getContainerIds() {
    return this.slots
      ?.map((slot) =>
        typeof slot == "object"
          ? GoogletagManager.getSlotContainerId(slot)
          : slot || null
      )
      .filter((i) => i != null);
  }

  adapterComplete(adapter) {
    if (this.auctionComplete) {
      Logger.log(
        `Got complete from ${adapter.name} after auction already completed. It won't be used.`
      );
      return;
    }
    Logger.log(`Got complete from ${adapter.name}`);

    //If all adapters are done
    if (!this.adapters.filter((a) => !a.auctionComplete).length) {
      clearTimeout(this.timeoutTimer);

      Logger.log("All adapters are done.");
      this.completeAuction();
    } else {
      Logger.log("not all adapters done");
    }
  }

  completeAuction() {
    this.auctionComplete = true;
    const slots = this.getSlots();
    Logger.log("Auction complete");
    if (this.onCompleteHandler) {
      Logger.log(
        "Calling custom onCompleteHandler. Note your handler will need to call googletag().pubads().refresh() itself"
      );
      this.onCompleteHandler(slots);
    } else {
      try {
        Logger.log("Calling pubads refresh", slots);
        googletag().pubads().refresh(slots);
      } catch (e) {
        Logger.log("Error refreshing ads", e);
      }
    }
    Logger.endGroup();
  }

  auctionTimeout() {
    Logger.log(this.adapters);
    Logger.log(
      `Auction timeout called before these adapters completed: ${this.adapters
        .filter((a) => !a.auctionComplete)
        .map((a) => a.name)}`
    );
    if (this.auctionComplete) return;
    this.completeAuction();
  }

  run() {
    if (BlacklistManager.isUrlBlacklisted()) {
      Logger.log("Cancelling auction due to url being blacklisted");
      return;
    }
    googletag().cmd.push(() => {
      const slots = this.getSlots();
      const containers = this.getContainerIds();

      Logger.group(`Starting auction for ${containers?.join(", ")}.`);
      Logger.log("Using auction config:", this.config, "Slots:", slots);
      GoogletagManager.setGlobalTargeting();
      GoogletagManager.setSlotTargeting(containers);

      this.adapters.forEach((adapter) => adapter.getBids(slots, containers));
      this.timeoutTimer = setTimeout(() => this.auctionTimeout(), 3000);
    });
  }
  async update() {
    await UserIdManager.init();
  }
}
