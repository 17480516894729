import { EventEmitter } from "stream";
import Logger from "./Logger";
import { SlotUnion } from "./Types";

export class ContainerSize {
  width: number;
  height: number;

  constructor(width: number = 0, height: number = 0) {
    this.width = width;
    this.height = height;
  }

  equals(other: ContainerSize) {
    return this.width == other.width && this.height == other.height;
  }

  widthEquals(other: ContainerSize) {
    return this.width == other.width;
  }

  lessThan(other: ContainerSize) {
    return this.width < other.width || this.height < other.height;
  }

  widthLessThan(other: ContainerSize) {
    return this.width < other.width;
  }

  widthLessThanOrEqual(other: ContainerSize) {
    return this.width <= other.width;
  }

  greaterThan(other: ContainerSize) {
    return this.width > other.width || this.height > other.height;
  }

  widthGreaterThan(other: ContainerSize) {
    return this.width > other.width;
  }

  /**
   * Filters the list of sizes against this size's width
   */
  filterListAgainstWidth(list: ContainerSize[]) {
    const newList = list.filter(
      (s) => s.widthLessThanOrEqual(this) && !s.isZero()
    );
    Logger.log("Filter list", JSON.stringify(list), JSON.stringify(newList));
    return newList;
  }

  isZero() {
    return this.width == 0 && this.height == 0;
  }

  toArray() {
    return [this.width, this.height];
  }

  static fromElement(el: HTMLElement) {
    return new ContainerSize(el.clientWidth, el.clientHeight);
  }

  static fromWindow() {
    return new ContainerSize(
      document.documentElement.clientWidth,
      document.documentElement.clientHeight
    );
  }

  static getSmallestWidthFromArray(list: ContainerSize[]): ContainerSize {
    var sorted = list.sort((a, b) => a.width - b.width);
    return sorted.filter((i) => !i.isZero()).shift();
  }

  static getLargestWidthFromArray(list: ContainerSize[]): ContainerSize {
    var sorted = list.sort((a, b) => b.width - a.width);
    return sorted.filter((i) => !i.isZero()).shift();
  }
}

class ContainerSizeManager extends EventTarget {
  containerSizeMap: Map<string, ContainerSize> = new Map();

  setContainerSize(container: string, size: ContainerSize) {
    if (this.containerSizeMap.has(container)) {
      if (!this.containerSizeMap.get(container).widthEquals(size)) {
        this.containerSizeMap.set(container, size);
        this.dispatchEvent(
          new CustomEvent("sizeChanged", { detail: { container, size } })
        );
      }
    } else {
      this.containerSizeMap.set(container, size);
      this.dispatchEvent(
        new CustomEvent("sizeChanged", { detail: { container, size } })
      );
    }
  }

  getContainerSize(container: string): ContainerSize {
    const size = this.containerSizeMap.get(container);
    return size;
  }

  setContainerSizeFromElement(el: HTMLElement) {
    this.setContainerSize(el.id, ContainerSize.fromElement(el));
  }

  removeContainers(containers: string[]) {
    if (containers) {
      containers.forEach((c) => {
        this.containerSizeMap.delete(c);
      });
    } else {
      this.containerSizeMap.clear();
    }
  }
}

export default new ContainerSizeManager() as ContainerSizeManager;
