import AdSystem from "../";
import Logger, { SubLogger } from "../../Logger";
import {
  AuctionAdapterName,
  AuctionConfig,
  DestroySlotsOptions,
  SlotUnion,
} from "../../Types";
var logger = new SubLogger("DisabledAdSystem");

// DisabledAdSystem is a subclass of AdSystem
class DisabledAdSystem extends AdSystem {
  constructor() {
    logger.log("constructor");
    super();
  }
  display(slot: SlotUnion) {
    //non-op
  }

  loadAds(slots?: SlotUnion[], config?: AuctionConfig) {
    //non-op
  }

  refreshAds(slots?: SlotUnion[], config?: AuctionConfig) {
    //non-op
  }

  setDefaultAuctionConfig(config: AuctionConfig) {
    //non-op
  }

  useAdapters(adapterNames: AuctionAdapterName[]) {
    //non-op
  }

  do(fn: () => void): void {
    //non-op
  }

  clearSlotsById(slotContainerIds: string[]): boolean {
    return true;
  }

  clearSlots(slots?: SlotUnion[]): boolean {
    return true;
  }

  enableDebug(): void {
    //non-op
  }

  async addSlot(adUnit: string, containerId: string): Promise<void> {
    //non-op
  }

  async update(): Promise<void> {
    //non-op
  }

  destroySlots(slots?: SlotUnion[], options?: DestroySlotsOptions): void {
    //non-op
  }

  destroySlotsById(
    slotContainerIds: string[],
    options?: DestroySlotsOptions
  ): void {
    //non-op
  }

  getSlotContainerID(slot: SlotUnion): string {
    return "";
  }

  getSlotObject(slot: SlotUnion): googletag.Slot {
    throw new Error("Method not implemented.");
  }

  getSlotObjects(slots: SlotUnion[]): googletag.Slot[] {
    throw new Error("Method not implemented.");
  }

  setSlotSizeAndReset(slot: SlotUnion, width: number, height: number): boolean {
    return false;
  }

  getContainerIdsForAdUnit(adUnit: string): string[] {
    return [];
  }
}

export default DisabledAdSystem;
