import Logger from "./lib/Logger";
import { Mastodon, MastodonUnion } from "./lib/Mastodon";

console.log("Mastodon Loaded");
// add Mastodon to window interface
declare global {
  interface Window {
    Mastodon: MastodonUnion;
    freestar: any;
    pbjs: any;
    googletag: any;
    apstag: any;
    OneTrust: any;
    OptanonWrapper: any;
    OnetrustActiveGroups: any;
    flippxp: any;
    ID5: any;
    __uid2: any;
    ats: any;
  }
}

const mastodon: Mastodon = new Mastodon();

if (window.Mastodon) {
  if (Array.isArray(window.Mastodon)) {
    const arr = [...window.Mastodon];
    Logger.log("processing existing queue");
    window.Mastodon = mastodon;
    mastodon.addExistingTasks(arr);
  } else {
    console.error(
      "Detected attempt to load mastodon twice! Make sure you only load mastodon once."
    );
  }
} else {
  window.Mastodon = mastodon;
}
