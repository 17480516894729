import Logger from "../../Logger";
import RuleType from "./RuleType";

interface UrlRuleParams {
  urlRegex?: string;
  urlContains?: string;
  url?: string;
}

export default class UrlRuleType extends RuleType {
  parameters: UrlRuleParams;

  constructor(params: UrlRuleParams) {
    super(params);
  }

  async isApplicable(): Promise<boolean> {
    Logger.log(
      "UrlRuleType.isApplicable",
      this.parameters,
      window.location.href
    );
    if (this.parameters.url) {
      if (window.location.pathname === this.parameters.url) {
        Logger.log(
          "UrlRuleType.isApplicable true",
          this.parameters.url,
          window.location.pathname
        );
        return true;
      } else {
        Logger.log(
          "UrlRuleType.isApplicable (url) false",
          this.parameters.url,
          window.location.pathname
        );
      }
    }

    if (this.parameters.urlContains) {
      if (window.location.href.includes(this.parameters.urlContains)) {
        Logger.log(
          "UrlRuleType.isApplicable true",
          this.parameters,
          window.location.href
        );
        return true;
      } else {
        Logger.log(
          "UrlRuleType.isApplicable (urlContains) false",
          this.parameters.urlContains,
          window.location.href
        );
      }
    }
    if (this.parameters.urlRegex) {
      var regex = new RegExp(this.parameters.urlRegex, "i");
      if (regex.test(window.location.pathname)) {
        Logger.log(
          "UrlRuleType.isApplicable true",
          this.parameters,
          window.location.pathname
        );
        return true;
      } else {
        Logger.log(
          "UrlRuleType.isApplicable (urlRegex) false",
          this.parameters.urlRegex,
          window.location.pathname
        );
      }
    }
    Logger.log(
      "UrlRuleType.isApplicable false",
      this.parameters,
      window.location.href
    );
    return false;
  }
}
// Path: src/lib/ElementRule/RuleType.ts
