import BlacklistManager from "./BlacklistManager";
import ContainerSizeManager, { ContainerSize } from "./ContainerSizeManager";
import Logger from "./Logger";
import { MastodonOptions } from "./Types";
import { cdnPath } from "./Utils";

class ConfigManager {
  configData: Object;
  platform: string;
  env: string;
  site: string;
  adUnitToContainerIdsMap: Map<string, string[]> = new Map();
  containerIdToAdUnitMap: Map<string, string> = new Map();
  params: URLSearchParams;

  options: MastodonOptions = {
    crossDomainToken: null,
  };

  constructor() {
    this.configData = {};
    this.params = new URLSearchParams(window.location.search);
    if (this.options.config) {
      this.configData = this.options.config;
    }
  }

  async fetchConfig(site: string, env: string, platform: string) {
    try {
      const start = Date.now();
      this.env = env;
      this.site = site;
      const response = await window.fetch(cdnPath(`/${site}_${env}.json`));

      this.configData = await response.json();

      this.setPlatform(platform);
      Logger.log("Got config data", this.configData, Date.now() - start, "ms");
    } catch (e) {
      Logger.error("Error loading config! Cannot proceed", e);
    }
  }

  getAdSystem() {
    return this.configData["adSystem"] ?? "gam";
  }

  setPlatform(platform: string) {
    this.platform = platform;
  }

  hasAds() {
    if (BlacklistManager.isUrlBlacklisted()) {
      Logger.log("ConfigManager: URL is blacklisted, not showing ads");
      return false;
    }
    return (
      Object.keys(this.configData["desktop"]).length ||
      Object.keys(this.configData["mobile"]).length
    );
  }

  hasAdUnit(adUnit: string) {
    return adUnit in this.configData[this.platform];
  }
  getAmazonPubId() {
    return this.configData["amazonPubId"];
  }

  getFlippZoneId() {
    return this.configData["flippZoneId"] ?? null;
  }

  getOneTrustDataDomainScript() {
    return this.configData["oneTrustDataDomainScript"];
  }

  getAdUnitSizes(adUnit: string) {
    return this.configData[this.platform][adUnit]?.mediaTypes.banner.sizes;
  }

  getElementRules(): any[] {
    return this.configData["elementRules"];
  }

  getContainerSize(containerId: string) {
    const mediaTypes = this.getFilteredMediaTypes(containerId);
    return mediaTypes["banner"].sizes;
  }

  getAdUnitPath(adUnit: string) {
    return this.configData[this.platform][adUnit]?.adUnit;
  }

  getAdUnitConfig(adUnit: string) {
    return this.configData[this.platform][adUnit];
  }
  getAllAdUnitConfigs() {
    return this.configData[this.platform];
  }

  getFreestarId() {
    return this.configData["freestarId"];
  }

  getFilteredMediaTypes(containerId) {
    const adUnit = this.containerIdToAdUnitMap.get(containerId);
    const config = this.configData[this.platform][adUnit];
    const newMediaTypes = {};
    Object.keys(config.mediaTypes).forEach((key) => {
      const mediaType = config.mediaTypes[key];
      const { sizes } = mediaType;
      const s: Array<ContainerSize> = sizes.map(
        (s) => new ContainerSize(s[0], s[1])
      );

      let containerSize = ContainerSizeManager.getContainerSize(containerId);
      Logger.log(containerId, " size: ", JSON.stringify(containerSize));
      // //if the window is smaller than the container, use window size instead
      // if (!windowSize.isZero() && windowSize.widthLessThan(containerSize)) {
      //   containerSize = windowSize;
      // }

      // if we have a set size for this container, filter the sizes to only use one that match
      if (containerSize) {
        let filteredSizes = containerSize.filterListAgainstWidth(s);
        if (filteredSizes.length == 0 || filteredSizes[0]?.isZero()) {
          filteredSizes = [ContainerSize.getSmallestWidthFromArray(s)];
        }
        newMediaTypes[key] = {
          ...mediaType,
          sizes: filteredSizes.map((size) => size.toArray()),
        };
      } else {
        newMediaTypes[key] = mediaType;
      }
    });
    return newMediaTypes;
  }

  getPrebidAdUnitsConfig() {
    const config = [...this.containerIdToAdUnitMap.keys()]
      .map((containerId) => {
        const adUnit = this.containerIdToAdUnitMap.get(containerId);
        const config = this.configData[this.platform][adUnit];
        if (config.disabled) return null;
        return {
          code: containerId,
          bids: config.bids,
          mediaTypes: this.getFilteredMediaTypes(containerId),
          floors: config.floors,
        };
      })
      .filter((au) => !!au); //filter disabled units
    return config;
  }

  addAdUnitToContainerMapping(adUnit, containerId) {
    if (!this.adUnitToContainerIdsMap.has(adUnit)) {
      this.adUnitToContainerIdsMap.set(adUnit, []);
    }
    this.adUnitToContainerIdsMap.set(adUnit, [
      ...this.adUnitToContainerIdsMap.get(adUnit),
      containerId,
    ]);
    this.containerIdToAdUnitMap.set(containerId, adUnit);
  }

  getContainerIdsForAdUnit(adUnit) {
    return this.adUnitToContainerIdsMap.get(adUnit);
  }

  isAdunitDisabled(adUnit) {
    const config = this.getAdUnitConfig(adUnit);
    if (config) {
      return !!config.disabled;
    }
    return true;
  }

  isAdunitLazyLoad(adUnit) {
    const config = this.getAdUnitConfig(adUnit);
    if (config) {
      return !!config.lazyLoad;
    }
    return false;
  }

  isContainerDisabled(container) {
    const adunit = this.containerIdToAdUnitMap.get(container);
    if (!adunit) return true;
    return this.isAdunitDisabled(adunit);
  }

  getNoFillRefreshMultiplier(adUnit: string): number {
    const config = this.getAdUnitConfig(adUnit);
    if (config) {
      return config.noFillRefreshMultiplier || 2.0;
    }
    return 2.0;
  }

  getUrlParam(paramName) {
    return this.params.get(paramName);
  }

  getRemoteConfigObject() {
    return this.configData["remoteConfig"];
  }

  getRemoteConfig(key: string): any {
    if (!this.configData["remoteConfig"]) return null;
    return this.configData["remoteConfig"][key];
  }
}
export default new ConfigManager();
