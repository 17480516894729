import ConfigManager from "./ConfigManager";
import GeolocationRuleType from "./ElementRule/types/GeolocationRuleType";
import ElementRule from "./ElementRule";
import Logger from "./Logger";
import RuleType from "./ElementRule/types/RuleType";
import UrlRuleType from "./ElementRule/types/UrlRuleType";
import RuleAction from "./ElementRule/actions/RuleAction";
import HideRuleAction from "./ElementRule/actions/HideRuleAction";
import DisableAdsRuleAction from "./ElementRule/actions/DisableAdsRuleAction";
import ExecuteScriptRuleAction from "./ElementRule/actions/ExecuteScriptRuleAction";

console.log("ExecuteScriptRuleAction", ExecuteScriptRuleAction);
class ElementRuleManager {
  rules: ElementRule[] = [];

  async init() {
    Logger.log("ElementRuleManager init");
    var configs = ConfigManager.getElementRules();
    if (configs == null) {
      return;
    }

    configs.forEach((config) => {
      let rule = this.createElementRule(config);
      if (rule != null) {
        this.rules.push(rule);
      }
    });
    await this.performActions();
  }

  createElementRule(config) {
    let rule = new ElementRule();
    rule.elementSelector = config.element_selector;
    let ruleType = this.getRuleForType(config.type, config.parameters);
    if (!ruleType) {
      return null;
    }
    rule.ruleType = ruleType;
    ruleType.rule = rule;
    rule.action = this.getActionForType(config.action, config.parameters);
    if (!rule.action) {
      return null;
    }
    rule.action.rule = rule;
    return rule;
  }

  getRuleForType(type: string, params: any): RuleType | null {
    switch (type) {
      case "geolocation":
        return new GeolocationRuleType(params);
      case "url":
        return new UrlRuleType(params);
      default:
        Logger.error("Unknown rule type", type);
        return null;
    }
  }

  getActionForType(type: string, parameters: any): RuleAction | null {
    switch (type) {
      case "hide":
        return new HideRuleAction(parameters);
      case "disableAds":
        return new DisableAdsRuleAction(parameters);
      case "runScript":
        Logger.log(
          "Creating ExecuteScriptRuleAction",
          ExecuteScriptRuleAction,
          typeof ExecuteScriptRuleAction,
          ExecuteScriptRuleAction.prototype
        );
        const action = new ExecuteScriptRuleAction(parameters);
        Logger.log("Created ExecuteScriptRuleAction", action);
        return action;
      default:
        Logger.error("Unknown rule action", type);
        return null;
    }
  }

  async performActions() {
    Logger.log("ElementRuleManager performActions");

    await Promise.all(
      this.rules.map(async (r) => {
        if (await r.isApplicable()) {
          try {
            r.perform();
          } catch (e) {
            Logger.error("Error performing rule", e);
          }
        }
      })
    );
  }
}

export default new ElementRuleManager();
