import apstag from "./apstag";
import ConfigManager from "./ConfigManager";
import Logger from "./Logger";

class ApstagManager {
  configure() {
    const pubID = ConfigManager.getAmazonPubId();
    if (!pubID) return;
    const amazonInit = {
      pubID,
      adServer: "googletag",
      simplerGPT: true,
    };
    Logger.log("Initializing amazon", amazonInit);
    window.apstag.init(amazonInit);
  }
}

export default new ApstagManager();
